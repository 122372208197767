import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Spinner,
} from "reactstrap";
import ReactTable from "react-table";
import meta from "./meta.json";
import { ErrorAlert } from "../components/Alerts/ErrorAlert";
import "../styles/styles.css";
import "../styles/inputStyles.css";
import { Menu } from "../components/Dropdown/Dropdown";
import classNames from "classnames";
import DownloadCsv from "../components/DownloadCsv";

const colors = {
  green: "#4FB32C",
  yellow: "#F8BA00",
  global: "#00E4E4",
};

export default class ContentTable extends Component {
  state = {
    current_page: 0,
    data: [],
    features_data: [],
    viewIndex: null,
  };

  componentDidMount() {
    let token = localStorage.getItem("x-auth");

    if (token || this.props.auth.userToken) {
      let pathname = this.props.history.location.pathname;
      this.props.history.push(pathname);
    } else {
      this.props.history.push("/login");
    }
    this.setState({ features_data: this.props.features.features_data });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.auth !== prevProps.auth) {
      if (!this.props.auth.userToken) {
        this.props.history.push("/login");
      }
    }
    if (
      this.props.features.features_data !== prevProps.features.features_data
    ) {
      this.setState({ features_data: this.props.features.features_data });
    }
  }

  navigate = () => {
    this.props.history.push(`/add_feature`);
  };

  navigateToEdit = (obj) => {
    // let pathname = this.props.history.location.pathname;
    this.props.history.push({
      pathname: `/edit-mapping/${obj.feature_id}`,
      state: { edit: true, obj: obj },
    });
  };
  navigateToCreate = (obj) => {
    // let pathname = this.props.history.location.pathname;
    this.props.history.push({
      pathname: `/create-mapping/${obj.feature_id}`,
      state: { edit: true, obj: obj },
    });
  };

  navigateToView = (obj) => {
    let pathname = this.props.history.location.pathname;
    this.props.history.push({
      pathname: `/view-mapping/${obj.feature_id}`,
      state: { view: true, obj: obj },
    });
  };

  onPageChange = (pageIndex) => {
    this.setState({ current_page: pageIndex });
  };

  // onRowClick = (state, rowInfo, column, instance) => {
  // 	let pathname = this.props.history.location.pathname;
  // 	return {
  // 		onClick: (e) => {
  // 			delete rowInfo.original.actions;
  // 			if (column.Header !== 'Actions') {
  // 				this.props.history.push({
  // 					pathname: `/view/${rowInfo.original.id}`,
  // 					state: { view: true, obj: rowInfo.original },
  // 				});
  // 			}

  // 			this.setState({ viewIndex: rowInfo.viewIndex });
  // 		},
  // 	};
  // };

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true;
  };

  filterComponent = ({ filter, onChange }) => {
    return (
      <input
        onChange={(event) => onChange(event.target.value)}
        value={filter ? filter.value : ""}
        placeholder="&#61442;  Search"
        style={{
          width: "90%",
          color: "white",
          //fontWeight: "bold",
          fontFamily: "FontAwesome,sans-serif",
        }}
      />
    );
  };

  coloredValue = (prop) => {
    return (
      <p
        style={{
          color: prop.value === "Yes" ? colors.green : colors.yellow,
          fontWeight: "600",
        }}
      >
        {prop.value}
      </p>
    );
  };

  render() {
    let features_data = this.state.features_data.map((prop, key) => {
      return {
        id: prop.id,
        make: prop.make,
        model: prop.model,
        variant: prop.variant,
        feature: prop.part,
        feature_id: prop.id,
        map: prop.source_features.length !== 0 ? "Yes" : "No",
        source_features: prop.source_features,
        // Mapped_features_make: prop.source_features
        //   .map((feat, index) => {
        //     return feat.make;
        //   })
        //   .join(","),
        Mapped_features: (() => {
          let featureNames = [];
          prop.source_features.forEach((sourceFeature) => {
            let featureName = `${sourceFeature.make} ${sourceFeature.model} ${sourceFeature.variant} ${sourceFeature.part}`;
            featureNames.push(featureName);
          });
          return featureNames.join(",");
        })(),

        // approved: prop.approved === 0 ? 'No' : 'Yes',
        // critical_feature: prop.critical === 0 ? 'No' : 'Yes',
        // approved_by: prop.approver_first_name,
        // edited_by: prop.editor_first_name,
        // visual: prop.vision === 0 ? 'No' : 'Yes',
        // verbal: prop.verbal === 0 ? 'No' : 'Yes',
        //status: prop.status,
        actions: (
          <div className="actions-center">
            {
              /* <Button */
              //     onClick=
              //     color="neutral"
              //     size="sm"
              //     className={classNames("btn-icon btn-link")}
              //   >
              //     <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
              //   </Button>
              //   <Button
              //     onClick={() => {
              //       let obj = cms_data.find((o) => o.id === prop.id);
              //       delete obj.actions;
              //       this.navigateToView(obj);
              //     }}
              //     title="View"
              //     color="neutral"
              //     size="sm"
              //     className={classNames("btn-icon btn-link")}
              //   >
              //     <i className="fa fa-eye" aria-hidden="true"></i>
              //   </Button>{" "}
              //   {this.props.auth.role == "admin" && (
              //     <Button
              //       onClick={() => {
              //         let obj = cms_data.find((o) => o.id === prop.id);
              //         this.props.approve(obj.id);
              //       }}
              //       //color="primary"
              //       size="sm"
              //       className={classNames("btn-icon btn-link")}
              //     >
              //       <i
              //         title="Approve"
              //         className="fa fa-check-circle-o fa-2x"
              //         aria-hidden="true"
              //         style={{color:"#2BC20E"}}
              //       ></i>
              //     </Button>
              //   )}
              /* {/* <p style={{ fontSize: 22, fontWeight: "bold" }}>...</p>

            <div
              id={`${prop.id}`}
              style={{
                width: 100,
                height: 100,
                backgroundColor: "red",
                display: "none",
              }}
            ></div> */
            }
            <Menu
              item={prop}
              index={this.state.viewIndex}
              disabled={prop.source_features.length === 0 ? true : false}
              onCreateMappingClick={() => {
                let obj = features_data.find((o) => o.id === prop.id);
                delete obj.actions;
                this.navigateToCreate(obj);
              }}
              onEditMappingClick={() => {
                let obj = features_data.find((o) => o.id === prop.id);
                delete obj.actions;
                this.navigateToEdit(obj);
              }}
              onViewMappingClick={() => {
                let obj = features_data.find((o) => o.id === prop.id);
                delete obj.actions;
                this.navigateToView(obj);
              }}
              onDeleteMappingClick={() => {
                let obj = features_data.find((o) => o.id === prop.id);

                this.props.deleteFeatureMapping(obj.feature_id);
                // this.navigateToView(obj);
              }}
              onDeleteFeatureClick={() => {
                this.props.deleteFeature(prop.id);
              }}
              role={this.props.auth.role}
              {...this.props}
            />
            {/* <Button
              onClick={() => {
                this.props.deleteFeature(prop.id);
              }}
              color="danger"
              size="sm"
              className={classNames("btn-icon btn-link")}
            >
              <i className="tim-icons icon-simple-remove" />
            </Button>{" "} */}
          </div>
        ),
      };
    });

    return (
      <>
        {/* <Navbar history={this.props.history} /> */}
        <div className="content" style={{ backgroundColor: "#19191A" }}>
          <Row>
            <Col lg="12">
              <Card
                className="card_bg"
                style={{
                  backgroundColor: "#222223",
                }}
              >
                <CardHeader>
                  <CardTitle
                    tag="h4"
                    style={{
                      fontWeight: "bold",
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ color: colors.global }}>Features</p>{" "}
                    <div>
                      <DownloadCsv
                        data={(() => {
                          return features_data.map((f) => {
                            return {
                              id: f.id,
                              make: f.make,
                              model: f.model,
                              variant: f.variant,
                              feature: f.feature,
                              feature_id: f.id,
                              mapped: f.map,
                              Mapped_features: f.Mapped_features,
                            };
                          });
                        })()}
                      />
                      <Button
                        className="btn-simple"
                        style={{
                          color: colors.global,
                          borderColor: colors.global,
                        }}
                        onClick={() => this.navigate()}
                      >
                        <i className="fa fa-plus" /> Add Feature
                      </Button>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody style={{}}>
                  {this.props.features.loadingFeatures ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner size="large" />
                    </div>
                  ) : (
                    <ReactTable
                      data={features_data}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        this.filterCaseInsensitive(filter, row)
                      }
                      resizable={true}
                      onPageChange={this.onPageChange}
                      columns={[
                        {
                          Header: "#",
                          filterable: false,
                          sortable: false,
                          headerStyle: {
                            textAlign: "left",
                            color: colors.global,
                            fontWeight: "500",
                            fontSize: "1.1em",
                            textTransform: "capitalize",
                          },
                          width: 50,
                          accessor: "id",
                          Cell: (prop) => {
                            return (
                              <div>
                                {prop.viewIndex +
                                  1 +
                                  this.state.current_page * 20}
                              </div>
                            );
                          },
                        },
                        {
                          Header: "Make",
                          accessor: "make",
                          sortable: false,
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "left",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) => this.filterComponent(props),
                        },

                        {
                          Header: "Model",
                          accessor: "model",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "left",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) => this.filterComponent(props),
                        },
                        {
                          Header: "Variant",
                          accessor: "variant",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "left",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) => this.filterComponent(props),
                        },
                        {
                          Header: "Feature",
                          accessor: "feature",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "left",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) => this.filterComponent(props),
                        },
                        {
                          Header: "Mapped",
                          accessor: "map",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Cell: (prop) => this.coloredValue(prop),
                          style: { textAlign: "center" },

                          Filter: (props) => this.filterComponent(props),
                        },

                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                          headerStyle: {
                            textAlign: "center",
                            color: colors.global,
                            fontWeight: "500",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          style: {
                            overflow: "visible",
                          },
                        },
                      ]}
                      defaultPageSize={20}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      showPageSizeOptions={false}
                      className="-striped -highlight"
                      style={{ height: "500px" }}
                      // getTdProps={(prop) => {

                      // }}
                      //getTdProps={this.onRowClick}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            {/* <Button
              className="btn-simple"
              style={{
                backgroundColor: colors.global,
                borderColor: colors.global,
                position: "fixed",
                bottom: "20px",
                right: "30px",
                color: "white",
              }}
              onClick={() => this.navigate()}
            >
              <i className="fa fa-plus" /> Add Feature Content
            </Button> */}
          </Row>
        </div>
        {this.props.features.error && (
          <ErrorAlert
            message={this.props.features.error_msg}
            hide={() => this.props.dismissFeaturesError()}
          />
        )}
      </>
    );
  }
}
