import { prototype } from "aws-sdk/clients/acm";
import React from "react";

const videos = [
  "https://s3.ap-south-1.amazonaws.com/maruti.data/videos/Headlamps.mp4",
  "https://s3.ap-south-1.amazonaws.com/maruti.data/videos/Inbuilt+Infotainment+Music+-+New+Baleno+Alpha.mp4",
  "https://s3.ap-south-1.amazonaws.com/maruti.data/videos/Windshield.mp4",
];

export default function VideoScreen(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ padding: 10 }}>
        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
        <span style={{ marginLeft: 10, fontWeight: "bold" }}>Videos</span>
      </div>
      <div style={{ fontWeight: "bold", padding: 10 }}>{props.title}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100%",
        }}
      >
        {props.videos.length <= 0 ? (
          <div style={{ top: "50%", position: "absolute" }}>
            <p style={{ color: "ButtonText" }}>No Videos Found</p>
          </div>
        ) : (
          props.videos.map((v) => (
            <video
              src={v ? v : ""}
              width="90%"
              height="33%"
              controls
              style={{ margin: 5 }}
              key={v.toString()}
            />
          ))
        )}
      </div>
    </div>
  );
}
