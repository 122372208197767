const routes = [
  {
    collapse: true,
    name: "Nexa",
    icon: "fa fa-car",
    state: "pagesCollapse",
    views: [
      {
        path: "/baleno",
        name: "Baleno",
      },
      { path: "/ignis", name: "Ignis" },
      { path: "/ciaz", name: "Ciaz" },
      { path: "/s-cross", name: "S-Cross" },
      { path: "/xl6", name: "XL6" },
      { path: "/baleno2022", name: "Baleno 2022" },
      { path: "/xl62022", name: "XL6 2022" },
      { path: "/grandvitara2022", name: "Grand Vitara 2022" },
      { path: "/fronx", name: "Fronx" },
      { path: "/jimny", name: "Jimny" },
      { path: "/invicto", name: "Invicto" },
      { path: "/alto", name: "Alto" },
      { path: "/celerio", name: "Celerio" },
      { path: "/dzire", name: "Dzire" },
      { path: "/eeco", name: "Eeco" },
      { path: "/ertiga", name: "Ertiga" },
      { path: "/s-presso", name: "S-Presso" },
      { path: "/supercarry", name: "Super Carry" },
      { path: "/tourh1", name: "Tour H1" },
      { path: "/tourh3", name: "Tour H3" },
      { path: "/tourm", name: "Tour M" },
      { path: "/tours", name: "Tour S" },
      { path: "/vitarabrezza", name: "Vitara Brezza" },
      { path: "/wagonr", name: "WagonR" },
    ],
  },
  {
    path: "/create-mapping",
    name: "Feature Mapping",
    icon: "tim-icons icon-settings",
  },
  {
    path: "/features",
    name: "Features",
    icon: "fa fa-cog",
  },
];

export default routes;
