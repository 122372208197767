const INITIAL_STATE = {
  loadingFeatures: true,
  mappable_features: null,
  error: false,
  error_msg: null,
  uploading: false,
  showModal: false,
};

export const featureMapping = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_MAPPABLE_FEATURES_START":
      return {
        ...state,
        loadingFeatures: true,
      };
    case "FETCH_MAPPABLE_FEATURES_SUCCESS":
      return {
        ...state,
        loadingFeatures: false,
        mappable_features: action.payload,
      };
    case "ON_MAPPABLE_FEATURES_ERROR":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loadingFeatures: false,
        uploading: false,
        showModal: false,
      };
    case "DISMISS_MAPPABLE_FEATURES_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "CREATE_FEATURE_MAPPING_START":
      return {
        ...state,
        uploading: true,
        showModal: true,
      };
    case "CREATE_FEATURE_MAPPING_SUCCESS":
      return {
        ...state,
        uploading: false,
      };
    case "CLOSE_FEATURE_MAPPING_MODAL":
      return {
        ...state,
        showModal: false,
      };
    case "RESET_FEATURE_MAPPING":
      return {
        ...state,
        mappable_features: null,
      };
    default:
      return state;
  }
};
