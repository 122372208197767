import React from "react";
import "../styles/device-preview.css";
import ResultCard from "../components/ResultCard";
import VideoScreen from "../components/VideoScreen";
import PartDetail from "../components/PartDetail";
import MoreInfo from "../components/MoreInfo";
import Warnings from "../components/Warnings";
import Cautions from "../components/Cautions";
import { Row, Col } from "reactstrap";
const Preview = (props) => {
  const { obj } = props;

  return (
    <Row
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        minHeight: "100vh",
        flex: 1,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          flex: 1,
          paddingBottom: 10,
          paddingTop: 20,
        }}
      >
        <div className="marvel-device iphone8 gold">
          <div className="top-bar"></div>
          <div className="sleep"></div>
          <div className="volume"></div>
          <div className="camera"></div>
          <div className="sensor"></div>
          <div className="speaker"></div>
          <div className="screen">
            <div style={{ padding: 10, fontWeight: "bold" }}>
              {obj.display_name}
            </div>

            <ResultCard
              icon={
                obj.images[0]
                  ? obj.images[0]
                  : "https://s3.ap-south-1.amazonaws.com/maruti.data/placeholder_image.jpg"
              }
              title={obj.display_name}
              short_text={obj.short_text}
            />
            <ResultCard
              icon={
                obj.images[0]
                  ? obj.images[0]
                  : "https://s3.ap-south-1.amazonaws.com/maruti.data/placeholder_image.jpg"
              }
              title={obj.display_name}
              short_text={obj.short_text}
            />
            <ResultCard
              icon={
                obj.images[0]
                  ? obj.images[0]
                  : "https://s3.ap-south-1.amazonaws.com/maruti.data/placeholder_image.jpg"
              }
              title={obj.display_name}
              short_text={obj.short_text}
            />
          </div>
          <div className="home"></div>
          <div className="bottom-bar"></div>
        </div>
        <div className="marvel-device iphone8 gold">
          <div className="top-bar"></div>
          <div className="sleep"></div>
          <div className="volume"></div>
          <div className="camera"></div>
          <div className="sensor"></div>
          <div className="speaker"></div>
          <div className="screen">
            <PartDetail
              title={obj.display_name}
              blurb={obj.short_text}
              related_features={obj.linked_parts ? obj.linked_parts : []}
            />
          </div>
          <div className="home"></div>
          <div className="bottom-bar"></div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          flex: 1,
          paddingBottom: 10,
          paddingTop: 20,
        }}
      >
        <div className="marvel-device iphone8 gold">
          <div className="top-bar"></div>
          <div className="sleep"></div>
          <div className="volume"></div>
          <div className="camera"></div>
          <div className="sensor"></div>
          <div className="speaker"></div>
          <div className="screen">
            <VideoScreen videos={obj.videos} title={obj.display_name} />
          </div>
          <div className="home"></div>
          <div className="bottom-bar"></div>
        </div>
        <div className="marvel-device iphone8 gold">
          <div className="top-bar"></div>
          <div className="sleep"></div>
          <div className="volume"></div>
          <div className="camera"></div>
          <div className="sensor"></div>
          <div className="speaker"></div>
          <div className="screen">
            <MoreInfo
              title={obj.display_name}
              illustrationsTop={obj.illustrationsTop}
              illustrationsMiddle={obj.illustrationsMiddle}
              illustrationsBottom={obj.illustrationsBottom}
              description_top={obj.description_top}
              description_middle={obj.description_middle}
              description_bottom={obj.description_bottom}
            />
          </div>
          <div className="home"></div>
          <div className="bottom-bar"></div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          flex: 1,
          paddingBottom: 10,
          paddingTop: 20,
        }}
      >
        <div className="marvel-device iphone8 gold">
          <div className="top-bar"></div>
          <div className="sleep"></div>
          <div className="volume"></div>
          <div className="camera"></div>
          <div className="sensor"></div>
          <div className="speaker"></div>
          <div className="screen">
            <Warnings
              title={obj.display_name}
              warning_text={obj.warning_text}
            />
          </div>
          <div className="home"></div>
          <div className="bottom-bar"></div>
        </div>
        <div className="marvel-device iphone8 gold">
          <div className="top-bar"></div>
          <div className="sleep"></div>
          <div className="volume"></div>
          <div className="camera"></div>
          <div className="sensor"></div>
          <div className="speaker"></div>
          <div className="screen">
            <Cautions
              title={obj.display_name}
              caution_text={obj.caution_text}
            />
          </div>
          <div className="home"></div>
          <div className="bottom-bar"></div>
        </div>
      </div>
    </Row>
  );
};

export default Preview;
