import { requestApi } from "../../config/apiHandler";
import { signOut } from "../actions/auth";

export const fetchPartsStart = () => {
  return {
    type: "FETCH_PARTS_START",
  };
};

export const fetchParts = () => {
  return async (dispatch) => {
    dispatch(fetchPartsStart());

    const response = await requestApi("/parts", "GET", {});

    if (response.code === "success") {
      // let parts = response.data.parts;
      let parts = response.data.parts.filter((part) => part.active === 1);
      dispatch({
        type: "FETCH_PARTS_SUCCESS",
        payload: parts,
      });
    } else if (response.code === "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onPartsError(response.message));
    }
  };
};

export const onPartsError = (error) => {
  return {
    type: "ON_PARTS_ERROR",
    payload: error,
  };
};

export const dismissPartsError = () => {
  return {
    type: "DISMISS_PARTS_ERROR",
  };
};

export const createPartStart = () => {
  return {
    type: "CREATE_PART_START",
  };
};

export const createPart = (data) => {
  return async (dispatch) => {
    dispatch(createPartStart());

    const response = await requestApi("/parts", "POST", data);

    if (response.code === "success") {
      let part_id = response.data.part.id;

      dispatch({
        type: "CREATE_PART_SUCCESS",
        payload: part_id,
      });
    } else if (response.code === "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(createPartError(response.message));
    }
  };
};

export const createPartError = (error) => {
  return {
    type: "CREATE_PART_ERROR",
    payload: error,
  };
};
