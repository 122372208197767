import React, { Component } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  Form,
  FormGroup,
  CardHeader,
  CardTitle,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import { ErrorAlert } from "../components/Alerts/ErrorAlert";
import UploadAlert from "../components/Alerts/UploadAlert";

const customStyles = {
  singleValue: (provided, state) => ({
    ...provided,
    color: "red",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "red",
  }),
  input: (provided) => ({
    ...provided,
    color: "white",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: "red",
  }),
  container: (provided, state) => ({
    ...provided,
    color: "red",
  }),
};

export default class CreateFeature extends Component {
  state = {
    model: null,
    make: null,
    variant: null,
    feature: null,

    modelData: [],
    variantsData: [],
    featuresData: [],
    makes_data: [],

    edit: false,
    feature_ids: [],
    feature_id: null,
    view: false,
    isOpen: false,
    uploaded: 0,
    total: 0,
  };

  async componentDidMount() {
    document
      .getElementsByClassName("react-select__input")[2]
      .addEventListener("paste", (e) => e.preventDefault());

    let token = await localStorage.getItem("x-auth");
    if (token || this.props.auth.userToken) {
      this.setState(
        {
          edit: this.props.match.path == "/edit_feature/:id" ? true : false,
          makes_data: this.props.meta.makes,
          feature_id: this.props.match.params.feature_id,
        },
        () => {
          if (this.state.edit) {
            let obj = this.props.location.state.obj;

            this.setState({
              make: { label: obj.make },
              model: { label: obj.model },
              feature: { label: obj.feature },
              variant: { label: obj.variant },
            });
          }
        }
      );
    } else {
      this.props.history.push("/login");
    }
  }

  onChange = (e, key) => {
    let parts = this.props.parts.parts_data;
    let features = this.props.meta.features;
    if (key === "make") {
      let selectedMakeId = e.value;
      let models = Array.from(
        new Set(
          features
            .filter((f) => f.make_id === selectedMakeId)
            .map((f) => f.model)
        )
      );

      let unique_models = [];
      models.map((model) => {
        let modelId = features.find(
          (f) => f.make_id === selectedMakeId && f.model === model
        ).model_id;
        unique_models.push({
          value: modelId,
          label: model,
        });
      });
      this.setState({ [key]: e, modelData: unique_models });
    }
    if (key === "model") {
      let unique_parts = [];
      parts.map((part) => {
        unique_parts.push({
          value: part.id,
          label: part.part,
        });
      });

      this.setState({
        [key]: e,
        featuresData: unique_parts,
        variantsData: [],
        variant: null,
        feature: null,
      });
    }
    if (key == "feature") {
      let selectedMakeId = this.state.make.value;
      let selectedModelId = this.state.model.value;
      let variants = Array.from(
        new Set(
          features
            .filter(
              (f) =>
                f.make_id === selectedMakeId && f.model_id === selectedModelId
            )
            .map((f) => f.variant)
        )
      );

      let unique_variants = [];
      variants.map((variant) => {
        let variantId = features.find(
          (f) =>
            f.make_id === selectedMakeId &&
            f.model_id === selectedModelId &&
            f.variant === variant
        ).variant_id;
        unique_variants.push({
          value: variantId,
          label: variant,
        });
      });

      this.setState({ [key]: e, variantsData: unique_variants, variant: null });
    }

    if (key == "variant") {
      this.setState({
        [key]: e,
      });
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.auth !== prevProps.auth) {
      if (!this.props.auth.userToken) {
        this.props.history.push("/login");
      }
    }

    if (prevProps.meta.makes !== this.props.meta.makes) {
      this.setState({ makes_data: this.props.meta.makes });
    }
  }

  submit = () => {
    let features_data = [];

    if (this.state.variant) {
      features_data = this.state.variant.map((v) => {
        return {
          make_id: this.state.make.value,
          model_id: this.state.model.value,
          part_id: this.state.feature.value,
          variant_id: v.value,
        };
      });
    }

    this.props.createFeature(features_data);
  };

  capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  createOption = async (label) => {
    let data = {
      part: label.split(" ").map(this.capitalize).join(" "),
      code: label
        .replace(/[^a-zA-Z0-9 ]/g, " ")
        .split(/\ /g)
        .join("_")
        .replace(/_+/g, "_")
        .toLowerCase(),
    };
    await this.props.createPart(data);
    let features = this.props.meta.features;
    let value = this.props.parts.part_id;
    let selectedMakeId = this.state.make.value;
    let selectedModelId = this.state.model.value;
    let variants = Array.from(
      new Set(
        features
          .filter(
            (f) =>
              f.make_id === selectedMakeId && f.model_id === selectedModelId
          )
          .map((f) => f.variant)
      )
    );

    let unique_variants = [];
    variants.map((variant) => {
      let variantId = features.find(
        (f) =>
          f.make_id === selectedMakeId &&
          f.model_id === selectedModelId &&
          f.variant === variant
      ).variant_id;
      unique_variants.push({
        value: variantId,
        label: variant,
      });
    });
    this.setState({
      feature: { label: label, value: value },
      variantsData: unique_variants,
    });
  };

  hideModal = () => {
    this.props.closeFeatureModal();
    this.props.history.goBack();
  };

  render() {
    const { edit, view } = this.state;

    return (
      <div className="content" style={{ backgroundColor: "#19191A" }}>
        <Row className="mt-5">
          <Card
            className="ml-auto mr-auto"
            style={{ width: "80%", backgroundColor: "#232324", padding: 25 }}
          >
            <CardHeader
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <CardTitle
                tag="h4"
                style={{
                  color: "rgb(0,228,228)",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {this.state.edit ? "Edit Feature" : "Create Feature"}
              </CardTitle>
            </CardHeader>
            <Form action="#">
              <Row style={{ justifyContent: "center", padding: 10 }}>
                <Col md="5">
                  <label>Make</label>
                  <FormGroup>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={this.state.make}
                      onChange={(e) => this.onChange(e, "make")}
                      options={this.state.makes_data}
                      placeholder="Select Make"
                      isDisabled={edit || view}
                      styles={customStyles}
                    />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <label>Model</label>
                  <FormGroup>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={this.state.model}
                      onChange={(e) => this.onChange(e, "model")}
                      options={this.state.modelData}
                      placeholder="Select Model"
                      isDisabled={edit || view}
                      styles={customStyles}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{ justifyContent: "center", padding: 5 }}>
                <Col md="5">
                  <label>Feature</label>
                  <FormGroup>
                    <CreatableSelect
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={this.state.feature}
                      onChange={(value) => this.onChange(value, "feature")}
                      options={this.state.featuresData}
                      placeholder="Select Feature"
                      isDisabled={this.props.parts.loadingParts}
                      isLoading={this.props.parts.loadingParts}
                      styles={customStyles}
                      onCreateOption={this.createOption}
                    />
                  </FormGroup>
                </Col>
                <Col md="5">
                  <label>Variant</label>
                  <FormGroup>
                    <Select
                      className="react-select info"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={this.state.variant}
                      key={this.state.variant}
                      onChange={(value) => this.onChange(value, "variant")}
                      options={this.state.variantsData}
                      placeholder="Select Variant"
                      isMulti
                      isDisabled={
                        this.state.make == null ||
                        this.state.model == null ||
                        this.state.feature == null ||
                        edit ||
                        view
                      }
                      styles={customStyles}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>

            <Button
              color="info"
              onClick={this.submit}
              className="btn-simple"
              style={{
                color: "#33FF00",
                borderColor: "#33FF00",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: 155,
                height: 35,
                margin: 5,
                alignSelf: "center",
              }}
            >
              <i className="fa fa-file-alt" aria-hidden="true"></i>{" "}
              <span style={{ marginLeft: 5 }}>Submit</span>
            </Button>
          </Card>
        </Row>
        {this.props.features.showModal && (
          <UploadAlert
            uploading={this.props.features.uploading}
            hideModal={() => this.hideModal()}
          />
        )}
        {this.props.features.error && (
          <ErrorAlert
            message={this.props.features.error_msg}
            hide={() => this.props.dismissFeaturesError()}
          />
        )}
      </div>
    );
  }
}
