import React from "react";

const MoreInfo = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ padding: 10 }}>
        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
        <span style={{ marginLeft: 10, fontWeight: "bold" }}>
          Illustrations and Text
        </span>
      </div>
      <div style={{ fontWeight: "bold", padding: 10 }}>{props.title}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {props.illustrationsTop.map((v) => (
          <img
            src={v ? v : ""}
            width="90%"
            height="33%"
            style={{ margin: 5 }}
            key={v.toString()}
          />
        ))}
        <p style={{ color: "ButtonText", padding: 5, whiteSpace: "pre-wrap" }}>
          {props.description_top}
        </p>
        {props.illustrationsMiddle.map((v) => (
          <img
            src={v ? v : ""}
            width="90%"
            height="33%"
            style={{ margin: 5 }}
            key={v.toString()}
          />
        ))}
        <p style={{ color: "ButtonText", padding: 5, whiteSpace: "pre-wrap" }}>
          {props.description_middle}
        </p>
        {props.illustrationsBottom.map((v) => (
          <img
            src={v ? v : ""}
            width="90%"
            height="33%"
            style={{ margin: 5 }}
            key={v.toString()}
          />
        ))}
        <p style={{ color: "ButtonText", padding: 5, whiteSpace: "pre-wrap" }}>
          {props.description_bottom}
        </p>
      </div>
    </div>
  );
};

export default MoreInfo;
