import React from "react";

export default function PartDetail(props) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ padding: 10 }}>
        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
        <span style={{ marginLeft: 10, fontWeight: "bold" }}>Part Detail</span>
      </div>
      <div style={{ fontWeight: "bold", padding: 10 }}>{props.title}</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "justify",
          padding: 10,
          fontSize: 14,
          fontWeight: "lighter",
        }}
      >
        {props.blurb}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: 10,
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            margin: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "45%",
              height: 30,
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
              boxShadow: "20px 20px 60px #d9d9d9,-20px -20px 60px #ffffff",
            }}
          >
            Videos
          </div>
          <div
            style={{
              display: "flex",
              width: "45%",
              height: 30,
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
              boxShadow: "20px 20px 60px #d9d9d9,-20px -20px 60px #ffffff",
            }}
          >
            Warnings
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            margin: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "45%",
              height: 30,
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
              boxShadow: "20px 20px 60px #d9d9d9,-20px -20px 60px #ffffff",
            }}
          >
            Cautions
          </div>
          <div
            style={{
              display: "flex",
              width: "45%",
              height: 30,
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
              boxShadow: "20px 20px 60px #d9d9d9,-20px -20px 60px #ffffff",
            }}
          >
            Workshop
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            margin: 5,
          }}
        >
          <div
            style={{
              display: "flex",
              width: "45%",
              height: 30,
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
              boxShadow: "20px 20px 60px #d9d9d9,-20px -20px 60px #ffffff",
            }}
          >
            Owner's Manual
          </div>
          <div
            style={{
              display: "flex",
              width: "45%",
              height: 30,
              backgroundColor: "white",
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
              boxShadow: "20px 20px 60px #d9d9d9,-20px -20px 60px #ffffff",
            }}
          >
            More Info
          </div>
        </div>
      </div>
      <div
        style={{
          margin: 5,
          height: 40,
          display: "flex",
          backgroundColor: "white",
          width: "90%",
          alignItems: "center",
          padding: 2,
          justifyContent: "space-around",
          boxShadow: "20px 20px 60px #d9d9d9,-20px -20px 60px #ffffff",
        }}
      >
        How was your experience ?{" "}
        <i
          className="fa fa-thumbs-up"
          aria-hidden="true"
          style={{ color: "green" }}
        ></i>
        <i
          className="fa fa-thumbs-down"
          aria-hidden="true"
          style={{ color: "red" }}
        ></i>
      </div>

      <div style={{ padding: 10, fontWeight: "bold" }}>Related Features</div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          padding: 5,
        }}
      >
        {props.related_features.map((i) => (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 10,
            }}
            key={
              i.icon_url
                ? i.icon_url.toString()
                : "https://s3.ap-south-1.amazonaws.com/msil-s3-tdc-vision-production/feature_icons/default.png"
            }
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "10px 10px 30px #d9d9d9,-10px -10px 30px #ffffff",
                height: 70,
              }}
            >
              <img
                src={
                  i.icon_url
                    ? i.icon_url
                    : "https://s3.ap-south-1.amazonaws.com/msil-s3-tdc-vision-production/feature_icons/default.png"
                }
                alt="image"
                width="30%"
                height={50}
              />
            </div>
            <p style={{ color: "GrayText", marginTop: 10 }}>{i.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
