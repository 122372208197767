import { requestApi } from "../../config/apiHandler";
import { signOut } from "../actions/auth";

export const fetchFeaturesStart = () => {
  return {
    type: "FETCH_FEATURES_START",
  };
};
export const fetchFeaturesByIdStart = () => {
  return {
    type: "FETCH_FEATURES_BY_ID_START",
  };
};

export const fetchFeatures = () => {
  return async (dispatch) => {
    dispatch(fetchFeaturesStart());
    let EXCLUDE_VARIANT_IDS = [1, 2, 3, 4, 5];
    const response = await requestApi("/features", "GET", {});
    if (response.code === "success") {
      let features = response.data.features.filter((f, i) => {
        // return f.variant.toLowerCase().indexOf("master") < 0;
        return !EXCLUDE_VARIANT_IDS.includes(f.variant_id);
      });
      let finalFeature = features.filter((feature) => feature.active === 1);
      dispatch({
        type: "FETCH_FEATURES_SUCCESS",
        payload: finalFeature,
      });
    } else if (response.code === "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onFeaturesError(response.message));
    }
  };
};
export const fetchFeaturesById = (id) => {
  return async (dispatch) => {
    dispatch(fetchFeaturesByIdStart());

    const response = await requestApi(`/features/?feature_id=${id}`, "GET", {});
    if (response.code === "success") {
      // let features = response.data
      dispatch({
        type: "FETCH_FEATURE_BY_ID_SUCCESS",
        payload: response.data,
      });
    } else if (response.code === "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onFeaturesError(response.message));
    }
  };
};

export const onFeaturesError = (error) => {
  return {
    type: "ON_FEATURES_ERROR",
    payload: error,
  };
};

export const dismissFeaturesError = () => {
  return {
    type: "DISMISS_FEATURES_ERROR",
  };
};

export const createFeatureStart = () => {
  return {
    type: "CREATE_FEATURE_START",
  };
};

export const createFeature = (data) => {
  return async (dispatch) => {
    if (data.length === 0) {
      dispatch(onFeaturesError("Empty fields"));
    } else {
      dispatch(createFeatureStart());
      let counter = 0;
      data.map(async (d, i, a) => {
        let params = d;

        //params = JSON.stringify

        const response = await requestApi(
          "/features",
          "POST",
          JSON.stringify(params)
        );
        if (response.code == "success") {
          counter += 1;
          if (counter == a.length) {
            dispatch({
              type: "CREATE_FEATURE_SUCCESS",
            });
            dispatch(fetchFeatures());
          }
        } else if (response.code == "authn_fail") {
          dispatch(signOut());
        } else {
          dispatch(onFeaturesError(response.message));
          dispatch(closeFeatureModal());
        }
      });
    }
  };
};

export const closeFeatureModal = () => {
  return {
    type: "CLOSE_FEATURE_MODAL",
  };
};

export const deleteFeature = (id) => {
  return async (dispatch) => {
    const response = await requestApi(`/features/${id}`, "PUT", { active: 0 });
    if (response.code === "success") {
      dispatch(fetchFeatures());
    } else if (response.code === "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onFeaturesError(response.message));
    }
  };
};
