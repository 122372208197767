import React from "react";

export default function ResultCard(props) {
  return (
    <div
      style={{
        margin: 20,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        padding: 5,
        borderRadius: 11,
        boxShadow: "20px 20px 60px #d9d9d9,-20px -20px 60px #ffffff",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <img src={props.icon} alt="image" width="30%" height="70px" />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "70%",
            flexDirection: "column",
          }}
        >
          <h5
            style={{
              color: "ButtonText",
              alignSelf: "flex-start",
              padding: "10px 5px",
              fontWeight: "600",
            }}
          >
            {props.title}
          </h5>
          <h5
            style={{
              color: "ButtonText",
              fontWeight: "lighter",
              padding: "10px 5px",
              alignSelf: "flex-start",
            }}
          >
            {props.short_text}
          </h5>
        </div>
      </div>
      <div
        style={{ height: "1px", border: "0.5px solid black", width: "100%" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          marginTop: 5,
        }}
      >
        <div style={{ fontSize: 12, fontWeight: "bold" }}>VIEW MORE</div>
        <div style={{ fontSize: 12, fontWeight: "bold" }}>
          CONNECT TO WORKSHOP
        </div>
      </div>
    </div>
  );
}
