/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
// react plugin for creating notifications over the dashboard

import ContentUploadFormContainer from "../../containers/ContentUploadForm";
import ContentTableContainer from "../../containers/ContentTable";
import { connect } from "react-redux";

// core components
import AdminNavbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar/Sidebar.js";

import routes from "../../routes";
import CreateFeatureContainer from "../../containers/CreateFeature";
import FeatureTableContainer from "../../containers/FeatureTable";
import MappedFeaturesTable from "../../containers/MappedFeaturesTable";
import FeaturesMapping from "../../containers/FeaturesMapping";

var ps;

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeColor: "blue",
      sidebarMini: true,
      opacity: 0,
      sidebarOpened: false,
    };
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.classList.add("perfect-scrollbar-on");
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
      this.refs.mainPanel.addEventListener(
        "ps-scroll-y",
        this.showNavbarButton
      );
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }
    window.addEventListener("scroll", this.showNavbarButton);
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.classList.add("perfect-scrollbar-off");
      document.documentElement.classList.remove("perfect-scrollbar-on");
      this.refs.mainPanel.removeEventListener(
        "ps-scroll-y",
        this.showNavbarButton
      );
    }
    window.removeEventListener("scroll", this.showNavbarButton);
  }
  componentDidUpdate(e) {
    if (e.location.pathname !== e.history.location.pathname) {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++) {
          ps = new PerfectScrollbar(tables[i]);
        }
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }
  showNavbarButton = () => {
    if (
      document.documentElement.scrollTop > 50 ||
      document.scrollingElement.scrollTop > 50 ||
      this.refs.mainPanel.scrollTop > 50
    ) {
      this.setState({ opacity: 1 });
    } else if (
      document.documentElement.scrollTop <= 50 ||
      document.scrollingElement.scrollTop <= 50 ||
      this.refs.mainPanel.scrollTop <= 50
    ) {
      this.setState({ opacity: 0 });
    }
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };
  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };
  toggleSidebar = () => {
    this.setState({
      sidebarOpened: !this.state.sidebarOpened,
    });
    document.documentElement.classList.toggle("nav-open");
  };
  closeSidebar = () => {
    this.setState({
      sidebarOpened: false,
    });
    document.documentElement.classList.remove("nav-open");
  };
  render() {
    let admin =
      this.props.auth.role == "admin" ||
      localStorage.getItem("role") == "admin";
    let new_routes = routes;
    if (!admin) {
      new_routes = routes.filter((f) => f.name !== "Features");
    }
    return (
      <div className="wrapper" style={{ backgroundColor: "#19191A" }}>
        {/* <div className="rna-container">
          <NotificationAlert ref="notificationAlert" />
        </div> */}
        <div
          className="navbar-minimize-fixed"
          style={{ opacity: this.state.opacity }}
          style={{ backgroundColor: "#19191A" }}
        >
          <button
            className="minimize-sidebar btn btn-link btn-just-icon"
            onClick={this.handleMiniClick}
          >
            <i className="tim-icons icon-double-right visible-on-sidebar-regular text-muted" />
            <i className="tim-icons icon-double-left visible-on-sidebar-mini text-muted" />
          </button>
        </div>
        <Sidebar
          {...this.props}
          routes={new_routes}
          activeColor={this.state.activeColor}
          logo={{
            outterLink: null,
            text: "CMS",
            imgSrc: "",
          }}
          closeSidebar={this.closeSidebar}
        />
        <div
          className="main-panel"
          ref="mainPanel"
          data={this.state.activeColor}
          style={{ backgroundColor: "#19191A" }}
        >
          <AdminNavbar
            {...this.props}
            handleMiniClick={this.handleMiniClick}
            brandText="Content Management System"
            sidebarOpened={this.state.sidebarOpened}
            toggleSidebar={this.toggleSidebar}
          />
          <Switch>
            <Route path="/" exact component={ContentTableContainer} />
            <Route path="/features" exact component={FeatureTableContainer} />
            <Route
              path="/mapped-features"
              exact
              component={MappedFeaturesTable}
            />
            <Route path="/create-mapping" exact component={FeaturesMapping} />
            <Route path="/edit-mapping/:id" exact component={FeaturesMapping} />
            <Route path="/view-mapping/:id" exact component={FeaturesMapping} />
            <Route
              path="/create-mapping/:id"
              exact
              component={FeaturesMapping}
            />
            <Route path="/add" exact component={ContentUploadFormContainer} />
            <Route
              path="/add_feature"
              exact
              component={CreateFeatureContainer}
            />
            <Route
              path="/edit_feature/:id"
              exact
              component={CreateFeatureContainer}
            />
            <Route path="/:model" exact component={ContentTableContainer} />
            <Route
              path="/edit/:id"
              component={ContentUploadFormContainer}
              exact
            />
            <Route
              path="/view/:id"
              component={ContentUploadFormContainer}
              exact
            />
            <Redirect from="/" to="/:model" />
          </Switch>
          {/* {// we don't want the Footer to be rendered on full screen maps page
          this.props.location.pathname.indexOf("full-screen-map") !==
          -1 ? null : (
            <Footer fluid />
          )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Admin);
