import React, { useState } from "react";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import "../../styles/styles.css";
const styles = {
  color: "black",
  fontWeight: "500",
};

export const Menu = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <UncontrolledDropdown direction={props.index > 3 ? "up" : "down"}>
      <DropdownToggle
        aria-expanded={false}
        //color="primary"
        data-toggle="dropdown"
        id="dropdownMenuButton"
        className="customise"
      >
        ...
      </DropdownToggle>
      {props.history.location.pathname == "/features" ? (
        <DropdownMenu aria-labelledby="dropdownMenuButton" right>
          <DropdownItem
            style={styles}
            onClick={() => props.onViewMappingClick()}
            disabled={props.disabled}
          >
            <i className="fa fa-eye" aria-hidden="true"></i>{" "}
            <span>View Mapping</span>
          </DropdownItem>
          <DropdownItem
            style={styles}
            disabled={!props.disabled}
            onClick={() => props.onCreateMappingClick()}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>{" "}
            <span>Create Mapping</span>
          </DropdownItem>
          <DropdownItem
            style={styles}
            onClick={() => props.onEditMappingClick()}
          >
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
            <span>Edit Mapping</span>
          </DropdownItem>
          <DropdownItem
            style={styles}
            onClick={() => props.onDeleteMappingClick()}
            disabled={props.disabled}
          >
            <i className="fas fa-times" aria-hidden="true"></i>{" "}
            <span>Delete Mapping</span>
          </DropdownItem>
          <DropdownItem
            style={styles}
            onClick={() => props.onDeleteFeatureClick()}
          >
            <i className="fas fa-times" aria-hidden="true"></i>{" "}
            <span>Delete Feature</span>
          </DropdownItem>
        </DropdownMenu>
      ) : (
        <DropdownMenu aria-labelledby="dropdownMenuButton" right>
          <DropdownItem style={styles} onClick={() => props.onEditClick()}>
            <i className="fa fa-pencil-square-o" aria-hidden="true"></i>{" "}
            <span>Edit</span>
          </DropdownItem>
          <DropdownItem style={styles} onClick={() => props.onViewClick()}>
            <i className="fa fa-eye" aria-hidden="true"></i> <span>View</span>
          </DropdownItem>
          {props.role === "admin" && (
            <DropdownItem
              style={styles}
              onClick={() => props.onApproveClick()}
              disabled={props.item.approved === 1 ? true : false}
            >
              <i className="fa fa-check-circle-o" aria-hidden="true"></i>{" "}
              <span>Approve</span>
            </DropdownItem>
          )}
        </DropdownMenu>
      )}
    </UncontrolledDropdown>
  );
};
