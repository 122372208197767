import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  FormGroup,
  Col,
  Input,
  Card,
  CardHeader,
  CardTitle,
  Label,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import { ErrorAlert } from "../components/Alerts/ErrorAlert";
import FeatureModel from "../components/FeatureModel";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "../styles/featureMapping.css";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  closeFeatureMappingModal,
  createFeatureMapping,
  dismissMappableFeaturesError,
  fetchMappableFeatures,
  ResetFeatureMapping,
} from "../store/actions/featureMapping";
import UploadAlert from "../components/Alerts/UploadAlert";
import { fetchFeaturesById } from "../store/actions/features";

const FeaturesMapping = (props) => {
  const [loading, setLoading] = useState();
  const [SourcefeatureIds, setSourceFeatureIds] = useState([]);
  const [mappableFeatures, setMappableFeatures] = useState([]);
  const [targetFeatureId, setTargetFeatureId] = useState();
  const [IsSubmited, setIsSubmitted] = useState(false);
  const [loadingSourceFeature, setLoadingSourceFeature] = useState(false);
  const [sourceFeatures, setSourceFeatures] = useState(null);
  const unique_id = uuidv4();
  let pathname = props.history.location.pathname;

  const {
    mappable_features,
    loadingFeatues,
    error,
    error_msg,
    showModal,
    uploading,
  } = useSelector((state) => state.featureMapping);
  const feature = useSelector((state) => state.features);
  const Dispatch = useDispatch();
  useEffect(() => {}, [SourcefeatureIds]);

  useEffect(() => {
    if (props.history.location.pathname.includes === "/create-mapping") {
      Dispatch(ResetFeatureMapping());
      setSourceFeatureIds([]);
    }
  }, [props.history.location.pathname, mappableFeatures]);

  useEffect(() => {
    if (props.edit || props.view) {
      Dispatch(ResetFeatureMapping());
      const { feature_id, source_features } = props.location.state.obj;
      setTargetFeatureId(feature_id);
      setLoadingSourceFeature(true);
      setSourceFeatureIds([]);
      Dispatch(fetchMappableFeatures(feature_id, unique_id));
      setSourceFeatureIds(
        source_features.map((feat, index) => {
          return feat.id;
        })
      );
    }
  }, [props.edit, props.view]);

  useEffect(() => {
    if (mappable_features) {
      setMappableFeatures(mappable_features.feature.mappable_features);
      setLoadingSourceFeature(false);
    }
  }, [mappable_features]);
  useEffect(() => {
    if (feature.feature) {
      let data = feature.feature;
      setSourceFeatures(data[0].source_features);
      // setSourceFeatures(feature[0].source_features);
      setSourceFeatureIds(
        data[0].source_features.map((feat, index) => {
          return feat.id;
        })
      );
      // setLoadingSourceFeature(false);
    }
  }, [feature]);

  const handleFeatureRemove = (index) => {
    let temp = SourcefeatureIds;
    temp.splice(index, 1);

    setSourceFeatureIds([...temp]);
  };
  const handleFeatureAdd = (index) => {
    let temp = SourcefeatureIds;
    setSourceFeatureIds([...temp, ""]);
  };

  const updateTargetFeature = (id) => {
    setTargetFeatureId(id);
    setLoadingSourceFeature(true);
    // setSourceFeatureIds([]);
    Dispatch(fetchMappableFeatures(id));
    Dispatch(fetchFeaturesById(id));
  };

  const updateSourceFeature = (id, index) => {
    let sourceFeatureList = [...SourcefeatureIds];
    sourceFeatureList[index] = id;
    setSourceFeatureIds(sourceFeatureList);
  };

  const handleSubmit = () => {
    Dispatch(createFeatureMapping(targetFeatureId, SourcefeatureIds));
  };

  const hideModal = () => {
    setSourceFeatureIds([]);
    Dispatch(closeFeatureMappingModal());
    if (props.edit) {
      props.history.push({
        pathname: `/features`,
      });
    }
  };

  return (
    <>
      <div className="content" style={{ backgroundColor: "#19191A" }}>
        <Row>
          <Col lg="12">
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: 1,
                }}
              >
                <Spinner size="large" />
              </div>
            ) : (
              <>
                <Card
                  className="ml-auto mr-auto"
                  style={{ width: "90%", backgroundColor: "#232324" }}
                >
                  <CardHeader
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CardTitle
                      tag="h4"
                      style={{
                        color: "rgb(0,228,228)",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      To
                    </CardTitle>
                    <div style={{ display: "flex" }}></div>
                  </CardHeader>
                  <FeatureModel
                    features={props.features}
                    makes={props.makes}
                    source={false}
                    view={props.view}
                    disabled={props.edit || props.view}
                    editFeature={
                      props.edit || props.view
                        ? props.location.state.obj
                        : false
                    }
                    updateTargetFeature={updateTargetFeature}
                  />
                </Card>
                {/* <hr style={{ background: "#2d2d2d" }} /> */}
                <Card
                  className="ml-auto mr-auto"
                  style={{ width: "80%", backgroundColor: "#232324" }}
                >
                  <CardHeader
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CardTitle
                      tag="h4"
                      style={{
                        color: "rgb(0,228,228)",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      From
                    </CardTitle>
                    <div style={{ display: "flex" }}></div>
                  </CardHeader>
                  {loadingSourceFeature ? (
                    <div
                      className="content"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner size="large" />
                    </div>
                  ) : (
                    <TransitionGroup className="todo-list">
                      {SourcefeatureIds.map((e, index) => (
                        <CSSTransition
                          key={index}
                          timeout={300}
                          classNames="item"
                        >
                          <div key={index}>
                            <FeatureModel
                              key={index}
                              source={true}
                              features={mappableFeatures}
                              sourceFeatures={
                                sourceFeatures ? sourceFeatures[index] : null
                              }
                              view={props.view}
                              makes={props.makes}
                              index={index}
                              disabled={props.view}
                              editFeature={
                                props.edit || props.view
                                  ? props.location.state.obj
                                  : false
                              }
                              SourcefeatureId={SourcefeatureIds[index]}
                              updateSourceFeature={updateSourceFeature}
                              handleFeatureRemove={handleFeatureRemove}
                            />
                            <br style={{ background: "#2d2d2d" }} />
                          </div>
                        </CSSTransition>
                      ))}
                    </TransitionGroup>
                  )}
                  <Row>
                    <Col style={{ textAlign: "end" }}>
                      <Button
                        className="btn-link btn-icon btn-neutral btn-icon"
                        color="danger"
                        id="tooltip707467505"
                        size="sm"
                        title="Add"
                        type="button"
                        style={{ padding: 15 }}
                        disabled={
                          props.view ||
                          // mappable_features === null ||
                          loadingSourceFeature === true ||
                          SourcefeatureIds[SourcefeatureIds.length - 1] === ""
                        }
                        onClick={() => {
                          handleFeatureAdd();
                        }}
                      >
                        <i
                          className="tim-icons icon-simple-add"
                          style={{ fontWeight: "900", color: "yellow" }}
                        />
                      </Button>
                    </Col>
                  </Row>
                </Card>
                <Row
                  style={{ justifyContent: "center", padding: 5 }}
                  className="mt-5"
                >
                  <Button
                    color="info"
                    className="btn-simple"
                    disabled={
                      !targetFeatureId ||
                      props.view ||
                      // SourcefeatureIds.includes("") ||
                      SourcefeatureIds.length < 1
                    }
                    style={{
                      color: "#33FF00",
                      borderColor: "#33FF00",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: 155,
                      height: 35,
                      margin: 5,
                    }}
                    onClick={handleSubmit}
                  >
                    <i className="fa fa-file-alt" aria-hidden="true"></i>
                    <span style={{ marginLeft: 5 }}>Submit</span>
                  </Button>
                </Row>
              </>
            )}
          </Col>
        </Row>

        {error && (
          <ErrorAlert
            message={error_msg}
            hide={() => Dispatch(dismissMappableFeaturesError())}
          />
        )}
        {showModal && (
          <UploadAlert uploading={uploading} hideModal={hideModal} />
        )}
      </div>
    </>
  );
};

export default FeaturesMapping;
