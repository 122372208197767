import React, { useState } from "react";
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Spinner,
} from "reactstrap";
import ReactTable from "react-table";

import { ErrorAlert } from "../components/Alerts/ErrorAlert";
import { dismissCmsError } from "../store/actions/cms";

const colors = {
  green: "#4FB32C",
  yellow: "#F8BA00",
  global: "#00E4E4",
};

const MappedFeaturesTable = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);

  const onPageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
  };

  const filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true;
  };

  const filterComponent = ({ filter, onChange }) => {
    return (
      <input
        onChange={(event) => onChange(event.target.value)}
        value={filter ? filter.value : ""}
        placeholder="&#61442;  Search"
        style={{
          width: "90%",
          color: "white",
          //fontWeight: "bold",
          fontFamily: "FontAwesome,sans-serif",
        }}
      />
    );
  };

  return (
    <>
      <div className="content" style={{ backgroundColor: "#19191A" }}>
        <Row>
          <Col lg="12">
            <Card
              className="card_bg"
              style={{
                backgroundColor: "#222223",
              }}
            >
              <CardHeader>
                <CardTitle
                  tag="h4"
                  style={{
                    fontWeight: "bold",
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ color: colors.global }}>Contents</p>{" "}
                  <Button
                    className="btn-simple"
                    style={{
                      color: colors.global,
                      borderColor: colors.global,
                    }}
                    onClick={() => this.navigate()}
                  >
                    <i className="fa fa-plus" /> Map Feature
                  </Button>
                </CardTitle>
              </CardHeader>
              <CardBody style={{}}>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner size="large" />
                  </div>
                ) : (
                  <ReactTable
                    data={[]}
                    filterable
                    defaultFilterMethod={(filter, row) =>
                      filterCaseInsensitive(filter, row)
                    }
                    resizable={true}
                    onPageChange={onPageChange}
                    columns={[
                      {
                        Header: "#",
                        filterable: false,
                        sortable: false,
                        headerStyle: {
                          textAlign: "left",
                          color: colors.global,
                          fontWeight: "500",
                          fontSize: "1.1em",
                          textTransform: "capitalize",
                        },
                        width: 50,
                        accessor: "id",
                        Cell: (prop) => {
                          return (
                            <div>{prop.viewIndex + 1 + currentPage * 10}</div>
                          );
                        },
                      },
                      {
                        Header: "Make",
                        accessor: "make",
                        sortable: false,
                        headerStyle: {
                          color: colors.global,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        Filter: (props) => filterComponent(props),
                      },

                      {
                        Header: "Model",
                        accessor: "model",
                        headerStyle: {
                          color: colors.global,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        Filter: (props) => filterComponent(props),
                      },
                      {
                        Header: "Variant",
                        accessor: "variant",
                        headerStyle: {
                          color: colors.global,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        Filter: (props) => filterComponent(props),
                      },
                      {
                        Header: "Feature",
                        accessor: "feature",
                        headerStyle: {
                          color: colors.global,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        Filter: (props) => filterComponent(props),
                      },
                      {
                        Header: "Visual",
                        accessor: "visual",
                        headerStyle: {
                          color: colors.global,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        Filter: (props) => filterComponent(props),
                        Cell: (prop) => this.coloredValue(prop),
                        style: { textAlign: "center" },
                      },
                      {
                        Header: "Verbal",
                        accessor: "verbal",
                        headerStyle: {
                          color: colors.global,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        Filter: (props) => filterComponent(props),
                        Cell: (prop) => this.coloredValue(prop),
                        style: { textAlign: "center" },
                      },

                      {
                        Header: "Critical",
                        accessor: "critical_feature",
                        headerStyle: {
                          color: colors.global,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        Filter: (props) => filterComponent(props),
                        Cell: (prop) => this.coloredValue(prop),
                        style: { textAlign: "center" },
                      },
                      {
                        Header: "Edited By",
                        accessor: "edited_by",
                        headerStyle: {
                          color: colors.global,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        Filter: (props) => filterComponent(props),
                        style: { textAlign: "center" },
                      },
                      {
                        Header: "Approved",
                        accessor: "approved",
                        headerStyle: {
                          color: colors.global,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        Filter: (props) => filterComponent(props),
                        Cell: (prop) => this.coloredValue(prop),
                        style: { textAlign: "center" },
                      },
                      {
                        Header: "Approved By",
                        accessor: "approved_by",
                        headerStyle: {
                          color: colors.global,
                          fontWeight: "500",
                          textAlign: "center",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        Filter: (props) => filterComponent(props),
                        style: { textAlign: "center" },
                      },

                      {
                        Header: "Actions",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        headerStyle: {
                          textAlign: "center",
                          color: colors.global,
                          fontWeight: "500",
                          fontSize: "1.01em",
                          textTransform: "capitalize",
                        },
                        style: {
                          overflow: "visible",
                        },
                      },
                    ]}
                    defaultPageSize={10}
                    showPaginationTop={false}
                    showPaginationBottom={true}
                    showPageSizeOptions={false}
                    className="-striped -highlight"
                    // getTdProps={(prop) => {

                    // }}
                    // getTdProps={this.onRowClick}
                  />
                )}
              </CardBody>
            </Card>
          </Col>
          {/* <Button
              className="btn-simple"
              style={{
                backgroundColor: colors.global,
                borderColor: colors.global,
                position: "fixed",
                bottom: "20px",
                right: "30px",
                color: "white",
              }}
              onClick={() => this.navigate()}
            >
              <i className="fa fa-plus" /> Add Feature Content
            </Button> */}
        </Row>
      </div>
      {error && (
        <ErrorAlert
          //   message={errorMessage}
          hide={() => this.props.dismissCmsError()}
        />
      )}
    </>
  );
};

export default MappedFeaturesTable;
