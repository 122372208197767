import React from "react";
import ContentTable from "../views/ContentTable";
import { connect } from "react-redux";
import {
  fetchData,
  getDocumentById,
  approve,
  dismissCmsError,
} from "../store/actions/cms";
import { fetchMetaData } from "../store/actions/meta";

const ContentTableContainer = (props) => {
  React.useEffect(() => {
    // let pathname = props.history.location.pathname;
    // let model_id = props.meta.models
    // 	? props.meta.models.filter((m) => `/${m.label.toLowerCase()}` === pathname)
    // 	: 1;
    props.fetchMetaData();
    //props.fetchData(model_id);
  }, []);

  React.useEffect(() => {
    let pathname = props.history.location.pathname;
    let model_id =
      props.meta.models && props.meta.models.length > 0
        ? props.meta.models.filter(
            (m) => `/${m.label.toLowerCase()}` === pathname
          )[0]
          ? props.meta.models.filter(
              (m) => `/${m.label.toLowerCase()}` === pathname
            )[0].value
          : 1
        : 1;

    props.fetchData(model_id);
  }, [props.meta.models, props.history.location.pathname]);

  return <ContentTable {...props} />;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cms: state.cms,
  meta: state.meta,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchData: (id) => dispatch(fetchData(id)),
    fetchMetaData: () => dispatch(fetchMetaData()),
    getDocumentById: (id) => dispatch(getDocumentById(id)),
    approve: (id, type, path) => dispatch(approve(id, type, path)),
    dismissCmsError: () => dispatch(dismissCmsError()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentTableContainer);
