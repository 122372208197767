import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { fetchFullData } from "../store/actions/cms";
import DownloadAlert from "./Alerts/DownloadAlert";

import DownloadCsv from "./DownloadCsv";

const DownloadCompleteData = (props) => {
  const [StartDownload, setStartDownload] = useState(false);
  const [modelId, setModelId] = useState();
  const Dispatch = useDispatch();
  const { loadingFullData, error, error_msg, fullData } = useSelector(
    (state) => state.cms
  );
  useEffect(() => {
    let pathname = props.history.location.pathname;
    let model_id =
      props.meta.models && props.meta.models.length > 0
        ? props.meta.models.filter(
            (m) => `/${m.label.toLowerCase()}` === pathname
          )[0]
          ? props.meta.models.filter(
              (m) => `/${m.label.toLowerCase()}` === pathname
            )[0].value
          : 1
        : 1;

    if (StartDownload) {
      Dispatch(fetchFullData(model_id));
    }
  }, [props.history.location.pathname, StartDownload]);

  const hideModal = () => {
    setStartDownload(false);
  };
  return (
    <>
      <Button onClick={() => setStartDownload(true)}>Export</Button>
      {StartDownload ? (
        <DownloadAlert
          loading={loadingFullData}
          fullData={fullData}
          error={error}
          error_msg={error_msg}
          hideModal={hideModal}
        />
      ) : null}
    </>
  );
};

export default DownloadCompleteData;
