import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Spinner,
} from "reactstrap";
import ReactTable from "react-table";
import Navbar from "../components/Navbar";
import DownloadCsv from "../components/DownloadCsv";
import { ErrorAlert } from "../components/Alerts/ErrorAlert";
import "../styles/styles.css";
import "../styles/inputStyles.css";
import { Menu } from "../components/Dropdown/Dropdown";
import DownloadCompleteData from "../components/DownloadCompleteData";

const colors = {
  green: "#4FB32C",
  yellow: "#F8BA00",
  global: "#00E4E4",
};

const fields = {
  model: null,
  make: null,
  variant: null,
  feature: null,
  short_text: "",
  short_text_state: "",
  description_top: "",
  description_state: "",
  description_middle: "",
  description_bottom: "",
  images: [],
  illustrationsTop: [],
  illustrationsMiddle: [],
  illustrationsBottom: [],
  videos: [],
  files: null,
  warning_text: "",
  warning_text_state: "",
  caution_text: "",
  caution_text_state: "",
  chapter_number: "",
  isCriticalFeature: false,
  modelData: [],
  variantsData: [],
  featuresData: [],
  makes_data: [],
  linked_parts_data: [],
  linked_parts: null,
  display_name: "",
  linked_to_pdf: "",
  meant_for: [],
  edit: false,
  feature_ids: [],
  id: null,
  view: false,
};

export default class ContentTable extends Component {
  state = {
    current_page: 0,
    data: [],
    cms_data: [],
    viewIndex: null,
  };

  componentDidMount() {
    let token = localStorage.getItem("x-auth");

    if (token || this.props.auth.userToken) {
      let pathname = this.props.history.location.pathname;
      this.props.history.push(pathname);
    } else {
      this.props.history.push("/login");
    }
    this.setState({ cms_data: this.props.cms.documents });
  }

  async componentDidUpdate(prevProps) {
    if (this.props.auth !== prevProps.auth) {
      if (!this.props.auth.userToken) {
        this.props.history.push("/login");
      }
    }
    if (this.props.cms.documents !== prevProps.cms.documents) {
      this.setState({ cms_data: this.props.cms.documents });
    }
  }

  navigate = () => {
    let pathname = this.props.history.location.pathname;
    this.props.history.push(`/add`);
  };

  navigateToEdit = (obj) => {
    let pathname = this.props.history.location.pathname;
    this.props.history.push({
      pathname: `/edit/${obj.id}`,
      state: { edit: true, obj: obj },
    });
  };

  navigateToView = (obj) => {
    let pathname = this.props.history.location.pathname;
    this.props.history.push({
      pathname: `/view/${obj.id}`,
      state: { view: true, obj: obj },
    });
  };

  onPageChange = (pageIndex) => {
    this.setState({ current_page: pageIndex });
  };

  onRowClick = (state, rowInfo, column, instance) => {
    let pathname = this.props.history.location.pathname;
    return {
      onClick: (e) => {
        delete rowInfo.original.actions;
        if (column.Header !== "Actions") {
          this.props.history.push({
            pathname: `/view/${rowInfo.original.id}`,
            state: { view: true, obj: rowInfo.original },
          });
        }

        this.setState({ viewIndex: rowInfo.viewIndex });
      },
    };
  };

  filterCaseInsensitive = (filter, row) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined
      ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase())
      : true;
  };

  filterComponent = ({ filter, onChange }, filterName) => {
    return (
      <input
        onChange={(event) => {
          // sessionStorage.setItem(filterName, event.target.value);
          onChange(event.target.value);
        }}
        value={filter ? filter.value : ""}
        // value={
        //   sessionStorage.getItem(filterName)
        //     ? sessionStorage.getItem(filterName)
        //     : ""
        // }
        placeholder="&#61442;  Search"
        style={{
          width: "90%",
          color: "white",
          //fontWeight: "bold",
          fontFamily: "FontAwesome,sans-serif",
        }}
      />
    );
  };

  coloredValue = (prop) => {
    return (
      <p
        style={{
          color: prop.value === "Yes" ? colors.green : colors.yellow,
          fontWeight: "600",
        }}
      >
        {prop.value}
      </p>
    );
  };

  render() {
    let cms_data = this.state.cms_data.map((prop, key) => {
      return {
        id: prop.id,
        make: prop.make,
        model: prop.model,
        variant: prop.variant,
        feature: prop.part,
        feature_id: prop.feature_id,
        //related_parts: prop.related_parts.map((m) => m.label + ","),
        // // display_name: prop.display_name,
        // short_description: prop.short_description,
        // long_description_top: prop.long_description_top,
        // long_description_middle: prop.long_description_middle,
        // long_description_bottom: prop.long_description_bottom,
        // caution_text: prop.caution_text,
        // warning_text: prop.warning_text,
        // chapter_number: prop.chapter_number,
        // icon_image: prop.icon_image,
        // illustration_top: prop.illustration_top,
        // illustration_middle: prop.illustration_middle,
        // illustration_bottom: prop.illustration_bottom,
        // videos: prop.videos,
        // linked_to_pdf: prop.linked_to_pdf,
        approved: prop.approved === 0 ? "No" : "Yes",
        critical_feature: prop.critical === 0 ? "No" : "Yes",
        approved_by: prop.approver_first_name,
        edited_by: prop.editor_first_name,
        visual: prop.vision === 0 ? "No" : "Yes",
        verbal: prop.verbal === 0 ? "No" : "Yes",
        //status: prop.status,
        actions: (
          <div className="actions-center">
            {
              /* <Button */
              //     onClick=
              //     color="neutral"
              //     size="sm"
              //     className={classNames("btn-icon btn-link")}
              //   >
              //     <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
              //   </Button>
              //   <Button
              //     onClick={() => {
              //       let obj = cms_data.find((o) => o.id === prop.id);
              //       delete obj.actions;
              //       this.navigateToView(obj);
              //     }}
              //     title="View"
              //     color="neutral"
              //     size="sm"
              //     className={classNames("btn-icon btn-link")}
              //   >
              //     <i className="fa fa-eye" aria-hidden="true"></i>
              //   </Button>{" "}
              //   {this.props.auth.role == "admin" && (
              //     <Button
              //       onClick={() => {
              //         let obj = cms_data.find((o) => o.id === prop.id);
              //         this.props.approve(obj.id);
              //       }}
              //       //color="primary"
              //       size="sm"
              //       className={classNames("btn-icon btn-link")}
              //     >
              //       <i
              //         title="Approve"
              //         className="fa fa-check-circle-o fa-2x"
              //         aria-hidden="true"
              //         style={{color:"#2BC20E"}}
              //       ></i>
              //     </Button>
              //   )}
              /* {/* <p style={{ fontSize: 22, fontWeight: "bold" }}>...</p>

            <div
              id={`${prop.id}`}
              style={{
                width: 100,
                height: 100,
                backgroundColor: "red",
                display: "none",
              }}
            ></div> */
            }
            <Menu
              item={prop}
              index={this.state.viewIndex}
              onEditClick={() => {
                let obj = cms_data.find((o) => o.id === prop.id);
                delete obj.actions;
                this.navigateToEdit(obj);
              }}
              onViewClick={() => {
                let obj = cms_data.find((o) => o.id === prop.id);
                delete obj.actions;
                this.navigateToView(obj);
              }}
              role={this.props.auth.role}
              //role="user"
              onApproveClick={() => {
                let obj = cms_data.find((o) => o.id === prop.id);
                this.props.approve(
                  obj.id,
                  "table",
                  this.props.history.location.pathname
                );
              }}
              {...this.props}
            />
          </div>
        ),
      };
    });
    return (
      <>
        {/* <Navbar history={this.props.history} /> */}
        <div className="content" style={{ backgroundColor: "#19191A" }}>
          <Row>
            <Col lg="12">
              <Card
                className="card_bg"
                style={{
                  backgroundColor: "#222223",
                }}
              >
                <CardHeader>
                  <CardTitle
                    tag="h4"
                    style={{
                      fontWeight: "bold",
                      flexDirection: "row",
                      display: "flex",
                      // border: "2px solid red",
                      justifyContent: "space-between",
                    }}
                  >
                    <p style={{ color: colors.global }}>Contents</p>{" "}
                    <div>
                      <DownloadCompleteData data={cms_data} {...this.props} />
                      <Button
                        className="btn-simple"
                        style={{
                          color: colors.global,
                          borderColor: colors.global,
                        }}
                        onClick={() => this.navigate()}
                      >
                        <i className="fa fa-plus" /> Add Feature Content
                      </Button>
                    </div>
                  </CardTitle>
                </CardHeader>
                <CardBody style={{}}>
                  {this.props.cms.loading ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Spinner size="large" />
                    </div>
                  ) : (
                    <ReactTable
                      data={cms_data}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        this.filterCaseInsensitive(filter, row)
                      }
                      resizable={true}
                      onPageChange={this.onPageChange}
                      columns={[
                        {
                          Header: "#",
                          filterable: false,
                          sortable: false,
                          headerStyle: {
                            textAlign: "left",
                            color: colors.global,
                            fontWeight: "500",
                            fontSize: "1.1em",
                            textTransform: "capitalize",
                          },
                          width: 50,
                          accessor: "id",
                          Cell: (prop) => {
                            return (
                              <div>
                                {prop.viewIndex +
                                  1 +
                                  this.state.current_page * 10}
                              </div>
                            );
                          },
                        },
                        {
                          Header: "Make",
                          accessor: "make",
                          sortable: false,
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) =>
                            this.filterComponent(props, "make"),
                        },

                        {
                          Header: "Model",
                          accessor: "model",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) =>
                            this.filterComponent(props, "model"),
                        },
                        {
                          Header: "Variant",
                          accessor: "variant",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) =>
                            this.filterComponent(props, "variant"),
                        },
                        {
                          Header: "Feature",
                          accessor: "feature",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) =>
                            this.filterComponent(props, "feature"),
                        },
                        {
                          Header: "Visual",
                          accessor: "visual",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) =>
                            this.filterComponent(props, "visual"),
                          Cell: (prop) => this.coloredValue(prop),
                          style: { textAlign: "center" },
                        },
                        {
                          Header: "Verbal",
                          accessor: "verbal",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) =>
                            this.filterComponent(props, "verbal"),
                          Cell: (prop) => this.coloredValue(prop),
                          style: { textAlign: "center" },
                        },

                        {
                          Header: "Critical",
                          accessor: "critical_feature",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) =>
                            this.filterComponent(props, "critical"),
                          Cell: (prop) => this.coloredValue(prop),
                          style: { textAlign: "center" },
                        },
                        {
                          Header: "Edited By",
                          accessor: "edited_by",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) =>
                            this.filterComponent(props, "edited_by"),
                          style: { textAlign: "center" },
                        },
                        {
                          Header: "Approved",
                          accessor: "approved",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) =>
                            this.filterComponent(props, "approved"),
                          Cell: (prop) => this.coloredValue(prop),
                          style: { textAlign: "center" },
                        },
                        {
                          Header: "Approved By",
                          accessor: "approved_by",
                          headerStyle: {
                            color: colors.global,
                            fontWeight: "500",
                            textAlign: "center",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          Filter: (props) =>
                            this.filterComponent(props, "approved_By"),
                          style: { textAlign: "center" },
                        },

                        {
                          Header: "Actions",
                          accessor: "actions",
                          sortable: false,
                          filterable: false,
                          headerStyle: {
                            textAlign: "center",
                            color: colors.global,
                            fontWeight: "500",
                            fontSize: "1.01em",
                            textTransform: "capitalize",
                          },
                          style: {
                            overflow: "visible",
                          },
                        },
                      ]}
                      defaultPageSize={10}
                      showPaginationTop={false}
                      showPaginationBottom={true}
                      showPageSizeOptions={false}
                      className="-striped -highlight"
                      // getTdProps={(prop) => {

                      // }}
                      getTdProps={this.onRowClick}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
            {/* <Button
              className="btn-simple"
              style={{
                backgroundColor: colors.global,
                borderColor: colors.global,
                position: "fixed",
                bottom: "20px",
                right: "30px",
                color: "white",
              }}
              onClick={() => this.navigate()}
            >
              <i className="fa fa-plus" /> Add Feature Content
            </Button> */}
          </Row>
        </div>
        {this.props.cms.error && (
          <ErrorAlert
            message={this.props.cms.error_msg}
            hide={() => this.props.dismissCmsError()}
          />
        )}
      </>
    );
  }
}
