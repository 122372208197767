import { CognitoIdentityServiceProvider } from "aws-sdk";
import { requestApi } from "../../config/apiHandler";
import { signOut } from "../actions/auth";
export const fetchMetaDataStart = () => {
  return {
    type: "FETCH_METADATA_START",
  };
};

export const fetchMetaData = () => {
  return async (dispatch) => {
    dispatch(fetchMetaDataStart());
    let EXCLUDE_VARIANT_IDS = [1, 2, 3, 4, 5];
    const response = await requestApi("/meta", "GET", {});
    // const response = await requestApi("/meta", "GET", {});
    if (response.code == "success") {
      let features = response.data.metadata.features.filter((f, i) => {
        // return f.variant.toLowerCase().indexOf("master") < 0;
        return !EXCLUDE_VARIANT_IDS.includes(f.variant_id);
      });
      // let makes = Array.from(
      //   new Set(
      //     features.map((f, i, a) => {
      //       return {
      //         value: f.make_id,
      //         label: f.make,
      //       };
      //     })
      //   )
      // ).filter(
      //   (v, i, a) =>
      //     a.findIndex((t) => t.label === v.label && t.value === v.value) === i
      // );
      let makes = Array.from(new Set(features.map((f) => f.make)));

      let unique_makes = [];
      makes.map((make) => {
        let makeId = features.find((f) => f.make === make).make_id;
        unique_makes.push({
          value: makeId,
          label: make,
        });
      });

      let models = Array.from(
        new Set(
          features.map((f, i, a) => {
            return {
              value: f.model_id,
              // label:(f.model==='Baleno 2022')?'Baleno2022':f.model,
              label: f.model.replace(/\ /g, ""),
            };
          })
        )
      ).filter(
        (v, i, a) =>
          a.findIndex((t) => t.value === v.value && t.model === v.model) === i
      );

      dispatch({
        type: "FETCH_METADATA_SUCCESS",
        payload: {
          makes: unique_makes,
          models: models,
          features: features,
        },
      });
    } else if (response.code == "authn_fail") {
      return dispatch(signOut());
    } else {
      return dispatch(fetchMetaDataFail(response.message));
    }
  };
};

export const fetchMetaDataFail = (msg) => {
  return {
    type: "FETCH_METADATA_FAIL",
    payload: msg,
  };
};

export const dismissError = () => {
  return {
    type: "DISMISS_ERROR_META",
  };
};
