import { requestApi } from "../../config/apiHandler";
import { signOut } from "../actions/auth";

export const fetchMappableFeaturesStart = () => {
  return {
    type: "FETCH_MAPPABLE_FEATURES_START",
  };
};
let uid;
export const fetchMappableFeatures = (id) => {
  return async (dispatch) => {
    dispatch(fetchMappableFeaturesStart());
    const response = await requestApi(
      `/features/mappable?feature_id=${id}`,
      "GET",
      {}
    );

    if (response.code === "success") {
      dispatch({
        type: "FETCH_MAPPABLE_FEATURES_SUCCESS",
        payload: response.data,
      });
    } else if (response.code === "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onFeaturesMappableError(response.message));
    }
  };
};

export const onFeaturesMappableError = (error) => {
  return {
    type: "ON_MAPPABLE_FEATURES_ERROR",
    payload: error,
  };
};

export const dismissMappableFeaturesError = () => {
  return {
    type: "DISMISS_MAPPABLE_FEATURES_ERROR",
  };
};

export const createFeatureMappingStart = () => {
  return {
    type: "CREATE_FEATURE_MAPPING_START",
  };
};
export const deleteFeatureMappingStart = () => {
  return {
    type: "DELETE_FEATURE_MAPPING_START",
  };
};

export const createFeatureMapping = (id, sourcefeatureIds) => {
  let source_feature_Ids = sourcefeatureIds.toString();
  return async (dispatch) => {
    dispatch(createFeatureMappingStart());

    const response = await requestApi(`/features/${id}`, "PUT", {
      source_feature_ids: source_feature_Ids,
    });
    if (response.code == "success") {
      dispatch({
        type: "CREATE_FEATURE_MAPPING_SUCCESS",
      });
    } else if (response.code == "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onFeaturesMappableError(response.message));
      dispatch(closeFeatureMappingModal());
    }
  };
};

export const closeFeatureMappingModal = () => {
  return {
    type: "CLOSE_FEATURE_MAPPING_MODAL",
  };
};

export const deleteFeatureMapping = (id) => {
  return async (dispatch) => {
    // dispatch(deleteFeatureMappingStart());
    const response = await requestApi(`/features/${id}`, "PUT", {
      source_feature_ids: 0,
    });
    if (response.code === "success") {
      window.location.reload(false);
      // dispatch({
      //   type: "DELETE_FEATURE_MAPPING_SUCCESS",
      // });
    } else if (response.code === "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onFeaturesMappableError(response.message));
    }
  };
};
export const ResetFeatureMapping = () => {
  return async (dispatch) => {
    dispatch({
      type: "RESET_FEATURE_MAPPING",
    });
  };
};
