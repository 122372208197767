import React, { Component } from "react";
import {
  Form,
  Row,
  FormGroup,
  Col,
  Input,
  Card,
  CardHeader,
  CardTitle,
  Label,
  Spinner,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import "../styles/styles.css";
import Switch from "react-bootstrap-switch";
import { v4 as uuidv4 } from "uuid";
import Preview from "../views/Preview";
import Select from "react-select";
import ImageUpload from "../components/CustomUpload/ImageUpload";
import VideoUpload from "../components/CustomUpload/VideoUpload";
import UploadAlert from "../components/Alerts/UploadAlert";
import { ErrorAlert } from "../components/Alerts/ErrorAlert";
import Navbar from "../components/Navbar";
import "../styles/formStyles.css";
import AWS from "aws-sdk";
const escapeStringRegexp = require("escape-string-regexp");
const s3 = new AWS.S3({
  accessKeyId: "AKIATE43PKUZC3HME4X6",
  secretAccessKey: "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",
});

const customStyles = {
  singleValue: (provided, state) => ({
    ...provided,
    color: "red",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "red",
  }),
  input: (provided) => ({
    ...provided,
    color: "white",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: "red",
  }),
  container: (provided, state) => ({
    ...provided,
    color: "red",
  }),
};

export default class ContentUploadForm extends Component {
  state = {
    model: null,
    make: null,
    variant: null,
    feature: null,
    short_text: "",
    short_text_state: "",
    description_top: "",
    description_state: "",
    description_middle: "",
    description_bottom: "",
    images: [],
    illustrationsTop: [],
    illustrationsMiddle: [],
    illustrationsBottom: [],
    videos: [],
    files: null,
    warning_text: "",
    warning_text_state: "",
    caution_text: "",
    caution_text_state: "",
    chapter_number: "",
    isCriticalFeature: false,
    modelData: [],
    variantsData: [],
    featuresData: [],
    makes_data: [],
    linked_parts_data: [],
    linked_parts: null,
    display_name: "",
    linked_to_pdf: [""],
    meant_for: [],
    edit: false,
    feature_ids: [],
    id: null,
    view: false,
    isOpen: false,
    uploaded: 0,
    total: 0,
  };

  async componentDidMount() {
    let token = await localStorage.getItem("x-auth");
    if (token || this.props.auth.userToken) {
      this.setState(
        {
          edit: this.props.match.path == "/edit/:id" ? true : false,
          view: this.props.match.path == "/view/:id" ? true : false,
          makes_data: this.props.meta.makes,
          id: this.props.match.params.id,
        },
        () => {
          if (this.state.edit || this.state.view) {
            let obj = this.props.location.state.obj;
            this.props.getDocumentById(this.props.match.params.id);
            this.setState({
              make: { label: obj.make },
              model: { label: obj.model },
              feature: { label: obj.feature },
              variant: { label: obj.variant },
            });
          }
        }
      );
    } else {
      this.props.history.push("/login");
    }
  }

  handleImages = (e) => {
    let selectedFiles = e.target.files;
    e.preventDefault();
    this.props.showIconSpinner("loadingIcon");
    Object.keys(selectedFiles).forEach((file) => {
      let reader = new FileReader();

      reader.onloadend = async () => {
        this.uploadOnS3(reader.result, "images");
        // image_urls.push(image_url);

        // this.setState({ images: image_urls });
      };
      reader.readAsDataURL(selectedFiles[file]);
    });
  };

  handleFiles = (e) => {
    let selectedFiles = e.target.files;
    let file_urls = [];
    e.preventDefault();
    Object.keys(selectedFiles).map((file) => {
      let reader = new FileReader();

      reader.onloadend = () => {
        file_urls.push({
          url: reader.result,
          name: selectedFiles[file].name,
          size: selectedFiles[file].size,
        });
        this.setState({ files: file_urls });
      };
      reader.readAsDataURL(selectedFiles[file]);
    });
  };

  handleIllustrations = (e, name) => {
    let selectedFiles = e.target.files;
    let image_urls = this.state[name];
    e.preventDefault();
    this.props.showIconSpinner(`loading${name}`);
    Object.keys(selectedFiles).forEach((file) => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.uploadOnS3(reader.result, [name]);
        // image_urls.push(reader.result);
        // this.setState({ [name]: image_urls });
      };
      reader.readAsDataURL(selectedFiles[file]);
    });
  };

  componentDidUpdate(prevProps) {
    if (this.props.auth !== prevProps.auth) {
      if (!this.props.auth.userToken) {
        this.props.history.push("/login");
      }
    }
    if (prevProps.cms.document !== this.props.cms.document) {
      let document = this.props.cms.document;
      let features = this.props.meta.features;
      if (this.state.edit || this.state.view) {
        let obj = this.props.location.state.obj;

        let featureId = obj.feature_id; // Comes from main screen
        let relatedFeatureIds = document.related_features.map((m) => m.id);
        //relatedFeatureIds = eval("[" + relatedFeatureIds + "]");

        let feature = features.find((f) => f.id === featureId);

        let relatedPartsList = [];
        let selectedPartsList = [];

        let filtered = features.filter(
          (f) =>
            f.make_id === feature.make_id && f.model_id === feature.model_id //&&
          // f.variant_id === feature.variant_id
        );

        filtered.map((f) => {
          if (relatedFeatureIds.indexOf(f.id) < 0) {
            relatedPartsList.push({
              value: f.id,
              label: f.part,
            });
          } else {
            selectedPartsList.push({
              value: f.id,
              label: f.part,
              icon_url: document.related_features.filter(
                (i) => f.id === i.id
              )[0].icon_url,
            });
          }
        });

        let meant_for = [];
        if (obj.visual == "Yes") {
          meant_for.push("vision");
        }
        if (obj.verbal == "Yes") {
          meant_for.push("verbal");
        }
        this.setState({
          make: { label: obj.make },
          model: { label: obj.model },
          feature: { label: obj.feature },
          variant: { label: obj.variant },
          images: document.images,
          linked_to_pdf: document.linked_to_pdf,
          display_name: document.display_name,
          short_text: document.short_text,
          illustrationsTop: document.illustration_top,
          illustrationsMiddle: document.illustration_middle,
          illustrationsBottom: document.illustration_bottom,
          isCriticalFeature: obj.critical_feature == "Yes" ? true : false,
          meant_for: meant_for,
          caution_text: document.caution_text,
          warning_text: document.warning_text,
          description_top: document.description_top,
          description_middle: document.description_middle,
          description_bottom: document.description_bottom,
          linked_parts_data: relatedPartsList,
          linked_parts: selectedPartsList,
          videos: document.videos,
          chapter_number: document.chapter,
        });
      }
    } else if (prevProps.meta.makes !== this.props.meta.makes) {
      this.setState({ makes_data: this.props.meta.makes });
    }
  }

  uploadOnS3 = (result, key) => {
    let folder = `content`;
    let fileName = `${uuidv4().replace(/\-/g, "")}.jpg`;
    // let readFileOptions = { encoding: 'utf8' }
    // const fileContent = (params.files.image.data)
    var Location = null;
    let buf = new Buffer(
      result.replace(/^data:image\/\w+;base64,/, ""),
      "base64"
    );
    let params = {
      Bucket: `arn:aws:s3:ap-south-1:216685040946:accesspoint/msil-s3-accesspoint-assist-public`,
      Key: `${folder}/${fileName}`, // File name you want to save as in S3
      Body: buf,
      ACL: "public-read",
      ContentType: `image/jpeg`,
      ContentEncoding: "base64",
    };
    s3.upload(params, async (err, data) => {
      if (err) {
        throw err;
      }

      const location = data.Location.replace(
        "public-216685040946.s3-accesspoint.ap-south-1.amazonaws.com",
        "public.s3.ap-south-1.amazonaws.com"
      ).replace("accesspoint-", "");
      Location = location;
      let files = this.state[key];
      files.push(Location);

      this.setState({ [key]: files }, () => {
        let name = key == "images" ? "loadingIcon" : `loading${key}`;
        this.props.hideIconSpinner(name);
      });
      //this.setState({ inputValue: Location });
    });
  };

  uploadOnS3Videos = (result, key) => {
    let folder = `content`;
    let fileName = `${uuidv4().replace(/\-/g, "")}.mp4`;
    // let readFileOptions = { encoding: 'utf8' }
    // const fileContent = (params.files.image.data)
    var Location = null;
    let buf = new Buffer.from(
      result.replace(/^data:video\/\w+;base64,/, ""),
      "base64"
    );
    let params = {
      Bucket: `arn:aws:s3:ap-south-1:216685040946:accesspoint/msil-s3-accesspoint-assist-public`,
      Key: `${folder}/${fileName}`, // File name you want to save as in S3
      Body: buf,
      ACL: "public-read",
      ContentType: `video/mp4`,
      ContentEncoding: "base64",
    };

    s3.upload(params, async (err, data) => {
      if (err) {
        throw err;
      }
      const location = data.Location.replace(
        "public-216685040946.s3-accesspoint.ap-south-1.amazonaws.com",
        "public.s3.ap-south-1.amazonaws.com"
      ).replace("accesspoint-", "");

      Location = location;
      let files = this.state[key];
      files.push(Location);

      this.setState({ [key]: files }, () => {
        this.props.hideIconSpinner(`loadingVideo`);
      });
      //this.setState({ inputValue: Location });
    }).on("httpUploadProgress", (evt) => {
      this.setState({ uploaded: evt.loaded, total: evt.total });
    });

    // s3.putObject(params)
    //   .on("success", (evt) => {
    //     console.log(evt);
    //   })
    //   .send((err,data) => {
    //     if (err) {
    //       // handle the error here
    //     }
    //     console.log(data);
    //   });
  };

  handleVideos = (e) => {
    let selectedFiles = e.target.files;
    e.preventDefault();
    this.props.showIconSpinner(`loadingVideo`);
    Object.keys(selectedFiles).forEach((file) => {
      let reader = new FileReader();

      reader.onloadend = () => {
        this.uploadOnS3Videos(reader.result, "videos");
        // video_urls.push(reader.result);
        // this.setState({ videos: video_urls });
      };
      reader.readAsDataURL(selectedFiles[file]);
    });
  };

  onChange = (value, type) => {
    this.setState({ [type]: value });
  };

  handleImageRemove = (m) => {
    let filteredImages = this.state.images.filter((f) => f !== m);

    this.setState({ images: filteredImages });
  };

  handleFileRemove = (m) => {
    let filteredFiles = this.state.files.filter((f) => f !== m);

    this.setState({ files: filteredFiles });
  };

  handleIllustrationRemove = (m, name) => {
    let filteredIllustrations = this.state[name].filter((f) => f !== m);

    this.setState({ [name]: filteredIllustrations });
  };

  handleVideoRemove = (m) => {
    let filteredVideos = this.state.videos.filter((f) => f !== m);

    this.setState({ videos: filteredVideos });
  };

  handleSubmit = () => {
    if (this.state.edit) {
      let obj = {};
      let id = this.props.match.params.id;
      obj.critical = this.state.isCriticalFeature ? 1 : 0;
      obj.verbal = this.state.meant_for.includes("verbal") ? 1 : 0;
      obj.vision = this.state.meant_for.includes("vision") ? 1 : 0;
      obj.template_reference = "msr_v1";
      obj.feature_id = this.props.location.state.obj.feature_id;
      obj.document_fields = [];
      this.state.display_name
        ? obj.document_fields.push({
            title: this.state.display_name,
            sequence: 1,
          })
        : console.log();
      this.state.images[0]
        ? obj.document_fields.push({
            icon_url: this.state.images[0],
            sequence: 1,
          })
        : console.log();
      this.state.short_text
        ? obj.document_fields.push({
            blurb: this.state.short_text,
            sequence: 1,
          })
        : console.log();
      this.state.videos.length > 0
        ? this.state.videos.map((u, i) => {
            obj.document_fields.push({
              video_url: u ? u : "",
              sequence: i + 1,
            });
          })
        : console.log();
      // this.state.videos[0]
      //   ? obj.document_fields.push({
      //       video_url: this.state.videos[0],
      //       sequence: 1,
      //     })
      //   : console.log();
      // obj.document_fields.push({
      //   warning_top:"",
      //   sequence:1
      // })
      this.state.caution_text
        ? obj.document_fields.push({
            caution: this.state.caution_text,
            sequence: 1,
          })
        : console.log();
      this.state.illustrationsTop.length > 0
        ? this.state.illustrationsTop.map((u, i) => {
            obj.document_fields.push({
              image_top_url: u ? u : "",
              sequence: i + 1,
            });
          })
        : console.log();
      this.state.description_top
        ? obj.document_fields.push({
            description_top: this.state.description_top,
            sequence: 1,
          })
        : console.log();
      this.state.illustrationsMiddle.length > 0
        ? this.state.illustrationsMiddle.map((u, i) => {
            obj.document_fields.push({
              image_middle_url: u ? u : "",
              sequence: i + 1,
            });
          })
        : console.log();
      this.state.description_middle
        ? obj.document_fields.push({
            description_middle: this.state.description_middle,
            sequence: 1,
          })
        : console.log();
      this.state.illustrationsBottom.length > 0
        ? this.state.illustrationsBottom.map((u, i) => {
            obj.document_fields.push({
              image_bottom_url: u ? u : "",
              sequence: i + 1,
            });
          })
        : console.log();
      this.state.description_bottom
        ? obj.document_fields.push({
            description_bottom: this.state.description_bottom,
            sequence: 1,
          })
        : console.log();
      this.state.warning_text
        ? obj.document_fields.push({
            warning: this.state.warning_text,
            sequence: 1,
          })
        : console.log();
      // this.state.linked_to_pdf
      // 	? obj.document_fields.push({
      // 			file_url: this.state.linked_to_pdf,
      // 			sequence: 1,
      // 	  })
      let s = 1;
      this.state.linked_to_pdf.length > 0 ||
      this.state.linked_to_pdf[0].length > 0
        ? this.state.linked_to_pdf.map((u, i) => {
            if (u) {
              obj.document_fields.push({
                file_url: u ? u : "",
                sequence: s++,
              });
            }
          })
        : console.log();
      this.state.linked_parts
        ? obj.document_fields.push({
            sequence: 1,
            related_feature_ids: this.state.linked_parts.map(
              (rpl) => rpl.value
            ),
          })
        : console.log();
      this.props.putData(JSON.stringify(obj), id);
    } else {
      let obj = {};

      obj.critical = this.state.isCriticalFeature ? 1 : 0;
      obj.verbal = this.state.meant_for.includes("verbal") ? 1 : 0;
      obj.vision = this.state.meant_for.includes("vision") ? 1 : 0;
      obj.template_reference = "msr_v1";
      obj.document_fields = [];
      this.state.display_name
        ? obj.document_fields.push({
            title: this.state.display_name,
            sequence: 1,
          })
        : console.log();
      this.state.images[0]
        ? obj.document_fields.push({
            icon_url: this.state.images[0],
            sequence: 1,
          })
        : obj.document_fields.push({
            icon_url:
              "https://s3.ap-south-1.amazonaws.com/msil-s3-tdc-vision-production/feature_icons/default.png",
            sequence: 1,
          });
      this.state.short_text
        ? obj.document_fields.push({
            blurb: this.state.short_text,
            sequence: 1,
          })
        : console.log();
      this.state.videos.length > 0
        ? this.state.videos.map((u, i) => {
            obj.document_fields.push({
              video_url: u ? u : "",
              sequence: i + 1,
            });
          })
        : console.log();
      // this.state.videos[0]
      //   ? obj.document_fields.push({
      //       video_url: this.state.videos[0],
      //       sequence: 1,
      //     })
      //   : console.log();
      // obj.document_fields.push({
      //   warning_top:"",
      //   sequence:1
      // })
      this.state.caution_text
        ? obj.document_fields.push({
            caution: this.state.caution_text,
            sequence: 1,
          })
        : console.log();
      this.state.illustrationsTop.length > 0
        ? this.state.illustrationsTop.map((u, i) => {
            obj.document_fields.push({
              image_top_url: u ? u : "",
              sequence: i + 1,
            });
          })
        : console.log();
      this.state.description_top
        ? obj.document_fields.push({
            description_top: this.state.description_top,
            sequence: 1,
          })
        : console.log();
      this.state.illustrationsMiddle.length > 0
        ? this.state.illustrationsMiddle.map((u, i) => {
            obj.document_fields.push({
              image_middle_url: u ? u : "",
              sequence: i + 1,
            });
          })
        : console.log();
      this.state.description_middle
        ? obj.document_fields.push({
            description_middle: this.state.description_middle,
            sequence: 1,
          })
        : console.log();
      this.state.illustrationsBottom.length > 0
        ? this.state.illustrationsBottom.map((u, i) => {
            obj.document_fields.push({
              image_bottom_url: u ? u : "",
              sequence: i + 1,
            });
          })
        : console.log();
      this.state.description_bottom
        ? obj.document_fields.push({
            description_bottom: this.state.description_bottom,
            sequence: 1,
          })
        : console.log();
      this.state.warning_text
        ? obj.document_fields.push({
            warning: this.state.warning_text,
            sequence: 1,
          })
        : console.log();
      // this.state.linked_to_pdf
      // 	? obj.document_fields.push({
      // 			file_url: this.state.linked_to_pdf,
      // 			sequence: 1,
      // 	  })
      // 	: console.log();

      let s = 1;
      this.state.linked_to_pdf.length > 0 ||
      this.state.linked_to_pdf[0].length > 0
        ? this.state.linked_to_pdf.map((u, i) => {
            if (u) {
              obj.document_fields.push({
                file_url: u ? u : "",
                sequence: s++,
              });
            }
          })
        : console.log();

      this.state.linked_parts
        ? obj.document_fields.push({
            sequence: 1,
            related_feature_ids: this.state.linked_parts.map(
              (rpl) => rpl.value
            ),
          })
        : console.log();
      this.state.chapter_number
        ? obj.document_fields.push({
            sequence: 1,
            chapter: this.state.chapter_number,
          })
        : console.log();
      this.props.postData(JSON.stringify(obj), this.state.feature_ids);
    }
  };

  clearState = () => {
    this.setState({
      model: null,
      make: null,
      variant: null,
      feature: null,
      short_text: "",
      short_text_state: "",
      description_top: "",
      description_state: "",
      description_middle: "",
      description_bottom: "",
      images: [],
      illustrationsTop: [],
      illustrationsMiddle: [],
      illustrationsBottom: [],
      videos: [],
      files: null,
      warning_text: "",
      warning_text_state: "",
      caution_text: "",
      caution_text_state: "",
      chapter_number: "",
      isCriticalFeature: false,
      modelData: [],
      variantsData: [],
      featuresData: [],
      linked_parts_data: [],
      makes_data: [],
      linked_parts: null,
      display_name: "",
      linked_to_pdf: [""],
      meant_for: [],
      edit: false,
      feature_ids: [],
    });
    this.props.history.goBack();
  };

  onLinkedPartChange = (e) => {
    if (e.target.checked) {
      let linked_parts = this.state.meant_for;
      linked_parts.push(e.target.value);
      this.setState({ meant_for: linked_parts });
    } else {
      if (this.state.meant_for.includes(e.target.value)) {
        let linked_parts = this.state.meant_for.filter(
          (f) => f !== e.target.value
        );
        this.setState({ meant_for: linked_parts });
      }
    }
  };

  hideModal = () => {
    if (this.state.view) {
      this.props.hideModal();
      this.props.history.goBack();
    } else {
      this.clearState();
      this.props.hideModal();
    }
  };

  onInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onInputChangel = (e, i) => {
    let contents = this.state.linked_to_pdf;
    contents[i] = e.target.value;
    this.setState({ [e.target.name]: [...contents] });
  };

  handleAddClick = () => {
    let contents = this.state.linked_to_pdf;
    this.setState({ linked_to_pdf: [...contents, ""] });
  };

  handleRemoveClick = (index) => {
    let contents = this.state.linked_to_pdf;
    contents.splice(index, 1);
    this.setState({ linked_to_pdf: [...contents] });
  };
  handleSwitch = (elem, state) => {
    this.setState({ isCriticalFeature: state });
  };

  onChange = (e, key) => {
    let features = this.props.meta.features;
    if (key === "make") {
      let selectedMakeId = e.value;
      let models = Array.from(
        new Set(
          features
            .filter((f) => f.make_id === selectedMakeId)
            .map((f) => f.model)
        )
      );

      let unique_models = [];
      models.map((model) => {
        let modelId = features.find(
          (f) => f.make_id === selectedMakeId && f.model === model
        ).model_id;
        unique_models.push({
          value: modelId,
          label: model,
        });
      });
      this.setState({ [key]: e, modelData: unique_models });
    }
    if (key === "model") {
      let selectedMakeId = this.state.make.value;
      let selectedModelId = e.value;
      // let linked_to_pdf = 'https://msil-s3-assist-public.s3.ap-south-1.amazonaws.com/owner_manuals/';
      // if (selectedModelId === 1) {
      // 	linked_to_pdf += 'Baleno.pdf';
      // } else if (selectedModelId === 2) {
      // 	linked_to_pdf += 'Ciaz.pdf';
      // } else if (selectedModelId === 3) {
      // 	linked_to_pdf += 'Ignis.pdf';
      // } else if (selectedModelId === 4) {
      // 	linked_to_pdf += 'S-Cross.pdf';
      // } else if (selectedModelId === 5) {
      // 	linked_to_pdf += 'XL6.pdf';
      // }
      let parts = Array.from(
        new Set(
          features
            .filter(
              (f) =>
                f.make_id === selectedMakeId && f.model_id === selectedModelId
            )
            .map((f) => f.part)
        )
      );

      let unique_parts = [];
      parts.map((part) => {
        let partId = features.find(
          (f) =>
            f.make_id === selectedMakeId &&
            f.model_id === selectedModelId &&
            f.part === part
        ).part_id;
        unique_parts.push({
          value: partId,
          label: part,
        });
      });

      this.setState({
        [key]: e,
        featuresData: unique_parts,
        linked_to_pdf: [""],
        variantsData: [],
        variant: null,
        feature: null,
      });
    }
    if (key == "feature") {
      let selectedMakeId = this.state.make.value;
      let selectedModelId = this.state.model.value;
      let selectedPartId = e.value;
      let variants = Array.from(
        new Set(
          features
            .filter(
              (f) =>
                f.make_id === selectedMakeId &&
                f.model_id === selectedModelId &&
                f.part_id === selectedPartId
            )
            .map((f) => f.variant)
        )
      );

      let unique_variants = [];
      variants.map((variant) => {
        let variantId = features.find(
          (f) =>
            f.make_id === selectedMakeId &&
            f.model_id === selectedModelId &&
            f.part_id === selectedPartId &&
            f.variant === variant
        ).variant_id;
        unique_variants.push({
          value: variantId,
          label: variant,
        });
      });

      this.setState({ [key]: e, variantsData: unique_variants, variant: null });
    }

    if (key == "variant") {
      let selectedMakeId = this.state.make.value;
      let selectedModelId = this.state.model.value;
      let selectedPartId = this.state.feature.value;

      // let selectedVariantId = e[e.length - 1].value;
      let selectedVariantIds = e ? e : [];
      let featureIds = [];

      selectedVariantIds.map((v, i) => {
        let feature_id = features.find(
          (f) =>
            f.make_id === selectedMakeId &&
            f.model_id === selectedModelId &&
            f.part_id === selectedPartId &&
            f.variant_id === v.value
        ).id;
        featureIds.push(feature_id);
      });
      let variants_ids = selectedVariantIds.map((v) => v.value);
      let filteredFeatures = features.filter(
        (f) =>
          f.make_id === selectedMakeId &&
          f.model_id === selectedModelId &&
          variants_ids.indexOf(f.variant_id) >= 0 &&
          f.part_id !== selectedPartId
      );
      let relatedPartsList = Array.from(
        new Set(filteredFeatures.map((f) => f.part))
      );
      let relatedPartsForDropdown = [];

      relatedPartsList.map((rpl) => {
        let feature = filteredFeatures.find((f) => f.part === rpl);
        relatedPartsForDropdown.push({
          value: feature.id,
          label: rpl,
        });
      });
      this.setState({
        [key]: e,
        feature_ids: featureIds,
        linked_parts_data: relatedPartsForDropdown,
        linked_parts: null,
      });
    }
    if (key == "linked_parts") {
      this.setState({ [key]: e });
    }
  };

  approve = () => {
    let id = this.props.match.params.id;
    let pathname = this.props.history.location.pathname;
    this.props.approve(id, "view", pathname);
  };

  preview = () => {
    this.setState({ isOpen: true });
  };
  closePreview = () => {
    this.setState({ isOpen: false });
  };

  render() {
    const { edit, view } = this.state;

    const row = edit ? this.props.location.state.obj : null;
    return (
      <>
        {/* <Navbar history={this.props.history} /> */}
        <div className="content" style={{ backgroundColor: "#19191A" }}>
          <Row className="mt-5">
            <Col lg="12">
              {(this.props.cms.fetchingDocument && this.state.edit) ||
              this.props.meta.loadingMeta ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  <Spinner size="large" />
                </div>
              ) : (
                <Card
                  className="ml-auto mr-auto"
                  style={{ width: "80%", backgroundColor: "#232324" }}
                >
                  <CardHeader
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <CardTitle
                      tag="h4"
                      style={{
                        color: "rgb(0,228,228)",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                    >
                      {this.state.edit
                        ? "Edit Feature Content"
                        : this.state.view
                        ? "Feature Content"
                        : "Upload Feature Content"}
                    </CardTitle>
                    <div style={{ display: "flex" }}>
                      {view &&
                        this.props.auth.role == "admin" &&
                        this.props.location.state.obj.approved == "No" && (
                          <Button
                            color="info"
                            onClick={this.approve}
                            className="btn-simple"
                            style={{
                              color: "#33FF00",
                              borderColor: "#33FF00",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: 155,
                              height: 35,
                            }}
                          >
                            <i
                              className="fa fa-check-circle-o"
                              aria-hidden="true"
                            ></i>
                            <span style={{ marginLeft: 5 }}>Approve</span>
                          </Button>
                        )}
                      <Button
                        color="info"
                        onClick={this.preview}
                        className="btn-simple"
                        style={{
                          color: "#FF6600",
                          borderColor: "#FF6600",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 155,
                          height: 35,
                        }}
                      >
                        <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                        <span style={{ marginLeft: 5 }}>Preview</span>
                      </Button>
                    </div>
                  </CardHeader>
                  <Form action="#">
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col md="5">
                        <label>Make</label>
                        <FormGroup>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={this.state.make}
                            onChange={(e) => this.onChange(e, "make")}
                            options={this.state.makes_data}
                            placeholder="Select Make"
                            isDisabled={edit || view}
                            styles={customStyles}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5">
                        <label>Model</label>
                        <FormGroup>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={this.state.model}
                            onChange={(e) => this.onChange(e, "model")}
                            options={this.state.modelData}
                            placeholder="Select Model"
                            isDisabled={edit || view}
                            styles={customStyles}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 5 }}>
                      <Col md="5">
                        <label>Feature</label>
                        <FormGroup>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={this.state.feature}
                            onChange={(value) =>
                              this.onChange(value, "feature")
                            }
                            options={this.state.featuresData}
                            placeholder="Select Feature"
                            isDisabled={edit || view}
                            styles={customStyles}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="5">
                        <label>Variant</label>
                        <FormGroup>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={this.state.variant}
                            key={this.state.variant}
                            onChange={(value) =>
                              this.onChange(value, "variant")
                            }
                            options={this.state.variantsData}
                            placeholder="Select Variant"
                            isMulti
                            isDisabled={
                              this.state.make == null ||
                              this.state.model == null ||
                              this.state.feature == null ||
                              edit ||
                              view
                            }
                            styles={customStyles}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Display Name</label>
                        <FormGroup>
                          <Input
                            type="textarea"
                            autoComplete="off"
                            value={this.state.display_name}
                            name="display_name"
                            onChange={(e) => this.onInputChange(e)}
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Short Description</label>
                        <FormGroup>
                          <Input
                            type="textarea"
                            autoComplete="off"
                            value={this.state.short_text}
                            name="short_text"
                            onChange={(e) => this.onInputChange(e)}
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Long Description (Top)</label>
                        <FormGroup>
                          <Input
                            type="textarea"
                            autoComplete="off"
                            value={this.state.description_top}
                            name="description_top"
                            onChange={(e) => this.onInputChange(e)}
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Long Description (Middle)</label>
                        <FormGroup>
                          <Input
                            type="textarea"
                            autoComplete="off"
                            value={this.state.description_middle}
                            name="description_middle"
                            onChange={(e) => this.onInputChange(e)}
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Long Description (Bottom)</label>
                        <FormGroup>
                          <Input
                            type="textarea"
                            autoComplete="off"
                            value={this.state.description_bottom}
                            name="description_bottom"
                            onChange={(e) => this.onInputChange(e)}
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Warning Text</label>
                        <FormGroup>
                          <Input
                            type="textarea"
                            autoComplete="off"
                            value={this.state.warning_text}
                            name="warning_text"
                            onChange={(e) => this.onInputChange(e)}
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Caution Text</label>
                        <FormGroup>
                          <Input
                            type="textarea"
                            autoComplete="off"
                            value={this.state.caution_text}
                            name="caution_text"
                            onChange={(e) => this.onInputChange(e)}
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Chapter Number</label>
                        <FormGroup>
                          <Input
                            type="textarea"
                            autoComplete="off"
                            value={this.state.chapter_number}
                            name="chapter_number"
                            onChange={(e) => this.onInputChange(e)}
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Select Icon Image</label>
                        <FormGroup>
                          {this.props.spinner.loadingIcon && (
                            <Spinner
                              style={{
                                position: "absolute",
                                top: "35%",
                                left: "12.5%",
                              }}
                              size={30}
                            />
                          )}
                          <ImageUpload
                            addBtnColor="default"
                            changeBtnColor="default"
                            images={this.state.images ? this.state.images : []}
                            handleImages={this.handleImages}
                            handleRemove={this.handleImageRemove}
                            name="icon"
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Illustration (Top)</label>
                        <FormGroup>
                          {this.props.spinner.loadingillustrationsTop && (
                            <Spinner
                              style={{
                                position: "absolute",
                                top: "35%",
                                left: "12.5%",
                              }}
                              size={30}
                            />
                          )}
                          <ImageUpload
                            addBtnColor="default"
                            changeBtnColor="default"
                            images={
                              this.state.illustrationsTop
                                ? this.state.illustrationsTop
                                : []
                            }
                            handleImages={(e) =>
                              this.handleIllustrations(e, "illustrationsTop")
                            }
                            handleRemove={(m) =>
                              this.handleIllustrationRemove(
                                m,
                                "illustrationsTop"
                              )
                            }
                            loading={this.props.spinner.loadingillustrationsTop}
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Illustration (Middle)</label>
                        <FormGroup>
                          {this.props.spinner.loadingillustrationsMiddle && (
                            <Spinner
                              style={{
                                position: "absolute",
                                top: "35%",
                                left: "12.5%",
                              }}
                              size={30}
                            />
                          )}
                          <ImageUpload
                            addBtnColor="default"
                            changeBtnColor="default"
                            images={
                              this.state.illustrationsMiddle
                                ? this.state.illustrationsMiddle
                                : []
                            }
                            handleImages={(e) =>
                              this.handleIllustrations(e, "illustrationsMiddle")
                            }
                            handleRemove={(m) =>
                              this.handleIllustrationRemove(
                                m,
                                "illustrationsMiddle"
                              )
                            }
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Illustration (Bottom)</label>
                        <FormGroup>
                          {this.props.spinner.loadingillustrationsBottom && (
                            <Spinner
                              style={{
                                position: "absolute",
                                top: "35%",
                                left: "12.5%",
                              }}
                              size={30}
                            />
                          )}
                          <ImageUpload
                            addBtnColor="default"
                            changeBtnColor="default"
                            images={
                              this.state.illustrationsBottom
                                ? this.state.illustrationsBottom
                                : []
                            }
                            handleImages={(e) =>
                              this.handleIllustrations(e, "illustrationsBottom")
                            }
                            handleRemove={(m) =>
                              this.handleIllustrationRemove(
                                m,
                                "illustrationsBottom"
                              )
                            }
                            disabled={view}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Select Videos</label>
                        <FormGroup>
                          {this.props.spinner.loadingVideo && (
                            <Spinner
                              style={{
                                position: "absolute",
                                top: "35%",
                                left: "12.5%",
                              }}
                              size={30}
                            />
                          )}
                          <VideoUpload
                            addBtnColor="default"
                            changeBtnColor="default"
                            videos={this.state.videos ? this.state.videos : []}
                            handleVideos={this.handleVideos}
                            handleRemove={this.handleVideoRemove}
                            disabled={view}
                          />
                          {this.props.spinner.loadingVideo && (
                            <progress
                              value={this.state.uploaded}
                              max={this.state.total}
                            />
                          )}
                        </FormGroup>
                      </Col>
                    </Row>
                    {/* <Row style={{ justifyContent: "center", padding: 10 }}>
                    <Col lg="10">
                      <label>Select Files</label>
                      <FormGroup>
                        <FileUpload
                          addBtnColor="default"
                          changeBtnColor="default"
                          files={this.state.files ? this.state.files : []}
                          handleFiles={this.handleFiles}
                          handleRemove={this.handleFileRemove}
                        />
                      </FormGroup>
                    </Col>
                  </Row> */}
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col lg="10">
                        <label>Link to PDF</label>
                        <>
                          <FormGroup>
                            {this.state.linked_to_pdf.map((x, i) => {
                              return (
                                <>
                                  <Input
                                    type="textarea"
                                    autoComplete="off"
                                    value={this.state.linked_to_pdf[i]}
                                    name="linked_to_pdf"
                                    onChange={(e) => this.onInputChangel(e, i)}
                                    disabled={view}
                                  />
                                  {this.state.linked_to_pdf.length !== 1 && (
                                    <Button
                                      color={this.props.removeBtnColor}
                                      className="btn-simple"
                                      onClick={() => this.handleRemoveClick(i)}
                                      // disabled={this.props.disabled}
                                      disabled={view}
                                      style={{
                                        color: "#FD1C03",
                                        borderColor: "#FD1C03",
                                        fontSize: 12,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        width: 155,
                                        height: 35,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <i className="fa fa-times"></i>{" "}
                                        <span style={{ marginLeft: 5 }}>
                                          Remove Page
                                        </span>
                                      </div>
                                    </Button>
                                  )}
                                  {this.state.linked_to_pdf.length - 1 ===
                                    i && (
                                    <Button
                                      color={this.props.addBtnColor}
                                      className="btn-simple"
                                      onClick={() => this.handleAddClick()}
                                      // disabled= {!this.state.linked_to_pdf[i]?"true":""}
                                      disabled={
                                        view || !this.state.linked_to_pdf[i]
                                      }
                                      style={{
                                        color: "rgb(248, 186, 0)",
                                        borderColor: "rgb(248, 186, 0)",
                                        fontSize: 12,
                                        display: "flex",
                                        width: 155,
                                        height: 35,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <i className="fa fa-image"></i>{" "}
                                        <span style={{ marginLeft: 5 }}>
                                          Add Page
                                        </span>
                                      </div>
                                    </Button>
                                  )}
                                </>
                              );
                            })}
                          </FormGroup>
                        </>
                        {/* <p>{this.state.linked_to_pdf}</p> */}
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Col
                        lg="10"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <label style={{ marginRight: 15 }}>
                          Is Feature Critical?
                        </label>
                        <Switch
                          value={this.state.isCriticalFeature}
                          offColor=""
                          offText={
                            <i className="tim-icons icon-simple-remove" />
                          }
                          onColor="custom"
                          onText={<i className="tim-icons icon-check-2" />}
                          onChange={(el, state) => this.handleSwitch(el, state)}
                          disabled={view}
                        />
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Label lg="2">Meant For :</Label>
                      <Col lg="8">
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={(e) => this.onLinkedPartChange(e)}
                              value="vision"
                              checked={
                                this.state.meant_for.includes("vision")
                                  ? true
                                  : false
                              }
                              disabled={view}
                            />
                            <span className="form-check-sign" />
                            Visual
                          </Label>
                        </FormGroup>
                        <FormGroup check inline>
                          <Label check>
                            <Input
                              type="checkbox"
                              onChange={(e) => this.onLinkedPartChange(e)}
                              value="verbal"
                              checked={
                                this.state.meant_for.includes("verbal")
                                  ? true
                                  : false
                              }
                              disabled={view}
                            />
                            <span className="form-check-sign" />
                            Verbal
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ justifyContent: "center", padding: 10 }}>
                      <Label lg="2">Related Parts :</Label>
                      <Col lg="8">
                        <FormGroup>
                          <Select
                            className="react-select info"
                            classNamePrefix="react-select"
                            //name="singleSelect"
                            value={this.state.linked_parts}
                            onChange={(value) =>
                              this.onChange(value, "linked_parts")
                            }
                            options={this.state.linked_parts_data}
                            placeholder="Select Feature"
                            isMulti
                            isDisabled={
                              this.state.variant == null ||
                              this.state.model == null ||
                              this.state.variant == null ||
                              this.state.feature == null ||
                              view
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                  <Row
                    style={{ justifyContent: "center", padding: 5 }}
                    className="mt-5"
                  >
                    {!view && (
                      <Button
                        color="info"
                        onClick={this.handleSubmit}
                        className="btn-simple"
                        style={{
                          color: "#33FF00",
                          borderColor: "#33FF00",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: 155,
                          height: 35,
                          margin: 5,
                        }}
                      >
                        <i className="fa fa-file-alt" aria-hidden="true"></i>
                        <span style={{ marginLeft: 5 }}>Submit</span>
                      </Button>
                    )}
                    {view &&
                      this.props.auth.role == "admin" &&
                      this.props.location.state.obj.approved == "No" && (
                        <Button
                          color="info"
                          onClick={this.approve}
                          className="btn-simple"
                          style={{
                            color: "#33FF00",
                            borderColor: "#33FF00",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            width: 155,
                            height: 35,
                            margin: 5,
                          }}
                        >
                          <i
                            className="fa fa-check-circle-o"
                            aria-hidden="true"
                          ></i>
                          <span style={{ marginLeft: 5 }}>Approve</span>
                        </Button>
                      )}

                    <Button
                      color="info"
                      onClick={this.preview}
                      className="btn-simple"
                      style={{
                        color: "#FF6600",
                        borderColor: "#FF6600",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 155,
                        height: 35,
                        margin: 5,
                      }}
                    >
                      <i className="fa fa-eye" aria-hidden="true"></i>{" "}
                      <span style={{ marginLeft: 5 }}>Preview</span>
                    </Button>
                  </Row>
                </Card>
              )}
            </Col>
            <Modal isOpen={this.state.isOpen}>
              <ModalHeader
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  flex: 1,
                }}
              >
                <div style={{ width: "100vw" }}>
                  <p style={{ color: "black" }}>Preview</p>
                  <Button
                    style={{ background: "none" }}
                    onClick={() => this.closePreview()}
                  >
                    <i
                      className="fa fa-times fa-2x"
                      aria-hidden="true"
                      style={{ color: "black" }}
                    ></i>
                  </Button>
                </div>
              </ModalHeader>
              <ModalBody>
                <Preview obj={this.state} />
              </ModalBody>
              <ModalFooter
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                <Button color="secondary" onClick={() => this.closePreview()}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </Row>
          {this.props.cms.showModal && !this.state.view && (
            <UploadAlert
              uploading={this.props.cms.uploading}
              hideModal={() => this.hideModal()}
            />
          )}
          {this.props.cms.showModal && this.state.view && (
            <UploadAlert
              uploading={!this.props.cms.approve}
              hideModal={() => this.hideModal()}
            />
          )}
          {this.props.cms.error && (
            <ErrorAlert
              message={this.props.cms.error_msg}
              hide={() => this.props.dismissCmsError()}
            />
          )}
        </div>
      </>
    );
  }
}
