import React, { useState } from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Spinner } from "reactstrap";
import DownloadCsv from "../DownloadCsv";
import "./downloadAlert.css";
const DownloadAlert = (props) => {
  return (
    <ReactBSAlert
      showCloseButton={false}
      style={{ display: "block", marginTop: "-100px" }}
      title={props.loading ? "Fetching Your Data" : "Successfully Fetched"}
      // onConfirm={() => props.hideModal()}
      onCancel={() => props.hideModal()}
      // customButtons={<React.Fragment></React.Fragment>}
      cancelBtnBsStyle="light"
      btnSize=""
      showConfirm={false}
    >
      {props.loading ? (
        <Spinner size="large" style={{ color: "#595959" }} />
      ) : (
        <DownloadCsv data={props.fullData} fullData={true} />
      )}
    </ReactBSAlert>
  );
};

export default DownloadAlert;
