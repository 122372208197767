const INITIAL_STATE = {
  loadingMeta: true,
  parts_data: [],
  makes: [],
  models: [],
  features: [],
  error: false,
  error_msg: null,
};

export const meta = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_METADATA_START":
      return {
        ...state,
        loadingMeta: true,
      };
    case "FETCH_METADATA_SUCCESS":
      return {
        ...state,
        loadingMeta: false,
        makes: action.payload.makes,
        models: action.payload.models,
        features: action.payload.features,
      };
    case "FETCH_METADATA_FAIL":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loadingMeta: false,
      };
    case "DISMISS_ERROR_META":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    default:
      return state;
  }
};
