const INITIAL_STATE = {
  loadingParts: false,
  parts_data: [],
  error: false,
  error_msg: null,
  part_id: null,
};

export const parts = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_PARTS_START":
      return {
        ...state,
        loadingParts: true,
      };
    case "FETCH_PARTS_SUCCESS":
      return {
        ...state,
        loadingParts: false,
        parts_data: action.payload,
      };
    case "ON_PARTS_ERROR":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loadingParts: false,
      };
    case "DISMISS_PARTS_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "CREATE_PART_START":
      return {
        ...state,
        loadingParts: true,
      };
    case "CREATE_PART_SUCCESS":
      return {
        ...state,
        part_id: action.payload,
        loadingParts: false,
      };
    case "CREATE_PART_ERROR":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loadingParts: false,
      };
    default:
      return state;
  }
};
