import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Col,
  Container,
} from "reactstrap";

import { ErrorAlert } from "../components/Alerts/ErrorAlert";
import AuthNavbar from "../components/AuthNavbar";

class Login extends React.Component {
  state = {
    loginFullName: "",
    loginEmail: "",
    loginPassword: "",
    loginFullNameState: "",
    loginEmailState: "",
    loginPasswordState: "",
  };

  verifyEmail = (value) => {
    var emailRex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  };
  // function that verifies if a string has a given length or not
  verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };
  // function that verifies if two strings are equal
  compare = (string1, string2) => {
    if (string1 === string2) {
      return true;
    }
    return false;
  };
  // function that verifies if value contains only numbers
  verifyNumber = (value) => {
    var numberRex = new RegExp("^[0-9]+$");
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  };
  // verifies if value is a valid URL
  verifyUrl = (value) => {
    try {
      new URL(value);
      return true;
    } catch (_) {
      return false;
    }
  };
  change = (event, stateName, type, stateNameEqualTo, maxValue) => {
    switch (type) {
      case "email":
        if (this.verifyEmail(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "password":
        if (this.verifyLength(event.target.value, 1)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "equalTo":
        if (this.compare(event.target.value, this.state[stateNameEqualTo])) {
          this.setState({ [stateName + "State"]: "has-success" });
          this.setState({ [stateNameEqualTo + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
          this.setState({ [stateNameEqualTo + "State"]: "has-danger" });
        }
        break;
      case "number":
        if (this.verifyNumber(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "length":
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "max-length":
        if (!this.verifyLength(event.target.value, stateNameEqualTo + 1)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "url":
        if (this.verifyUrl(event.target.value)) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "min-value":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo
        ) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "max-value":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value <= stateNameEqualTo
        ) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      case "range":
        if (
          this.verifyNumber(event.target.value) &&
          event.target.value >= stateNameEqualTo &&
          event.target.value <= maxValue
        ) {
          this.setState({ [stateName + "State"]: "has-success" });
        } else {
          this.setState({ [stateName + "State"]: "has-danger" });
        }
        break;
      default:
        break;
    }
    this.setState({ [stateName]: event.target.value });
  };

  loginClick = () => {
    if (this.state.loginEmailState === "") {
      this.setState({ loginEmailState: "has-danger" });
    }
    if (this.state.loginPasswordState === "") {
      this.setState({ loginPasswordState: "has-danger" });
    }

    if (
      this.state.loginEmailState === "has-success" &&
      this.state.loginPasswordState === "has-success"
    ) {
      this.props.signIn(this.state.loginEmail, this.state.loginPassword);
    }
  };

  async componentDidMount() {
    let token = await localStorage.getItem("x-auth");
    if (token || this.props.auth.userToken) {
      this.props.history.push("/baleno");
    } else {
      this.props.history.push("/login");
    }
  }

  async componentDidUpdate(prevProps) {
    if (this.props.auth !== prevProps.auth) {
      let token = await localStorage.getItem("x-auth");
      if (token || this.props.auth.userToken) {
        this.props.history.push("/baleno");
      } else {
        this.props.history.push("/login");
      }
    }
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  handlePress = (e) => {
    if (e.charCode === 13) {
      this.loginClick();
    }
  };

  render() {
    // taking all the states
    let {
      // login form
      loginEmailState,
      loginPasswordState,
    } = this.state;
    return (
      <>
        <AuthNavbar />
        <div className="wrapper wrapper-full-page" ref="fullPages">
          <div className={"full-page login"}>
            <div className="content" style={{ backgroundColor: "#fff" }}>
              <Container>
                <Col className="ml-auto mr-auto" md="8">
                  <Form id="LoginValidation">
                    <Card
                      style={{
                        backgroundColor: "#fff",
                        border: "0.5px solid black",
                      }}
                    >
                      <CardHeader>
                        <CardTitle
                          tag="h3"
                          style={{ color: "#000", textAlign: "center" }}
                        >
                          Content Management System
                        </CardTitle>
                        <CardTitle
                          tag="h4"
                          style={{ color: "#000", alignSelf: "center" }}
                        >
                          Login
                        </CardTitle>
                      </CardHeader>
                      <CardBody>
                        <FormGroup className={`has-label ${loginEmailState}`}>
                          <label style={{ color: "black", fontWeight: "500" }}>
                            Email Address *
                          </label>
                          <Input
                            name="email"
                            type="email"
                            style={{
                              color: "black",
                              background: "#fff",
                              border: "1px solid black",
                            }}
                            onChange={(e) =>
                              this.change(e, "loginEmail", "email")
                            }
                          />
                          {this.state.loginEmailState === "has-danger" ? (
                            <label className="error">
                              Please enter a valid email address.
                            </label>
                          ) : null}
                        </FormGroup>
                        <FormGroup
                          className={`has-label ${loginPasswordState}`}
                          onKeyPress={this.handlePress}
                        >
                          <label
                            style={{
                              color: "black",
                              fontWeight: "500",
                            }}
                          >
                            Password *
                          </label>
                          <Input
                            name="password"
                            type="password"
                            autoComplete="off"
                            style={{
                              color: "black",
                              background: "#fff",
                              border: "1px solid black",
                            }}
                            onChange={(e) =>
                              this.change(e, "loginPassword", "password")
                            }
                          />
                          {this.state.loginPasswordState === "has-danger" ? (
                            <label className="error">
                              This field is required.
                            </label>
                          ) : null}
                        </FormGroup>
                        <div className="category form-category">
                          * Required fields
                        </div>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button
                          color="#123748"
                          onClick={this.loginClick}
                          style={{ backgroundColor: "#123748" }}
                        >
                          Login
                        </Button>
                      </CardFooter>
                    </Card>
                  </Form>
                </Col>
              </Container>
              {this.props.auth.error && (
                <ErrorAlert
                  message={this.props.auth.error_msg}
                  hide={() => this.props.dismissError()}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
