import React from "react";
// used for making the prop types of this component

import { Button, Row, Col, Spinner } from "reactstrap";

import defaultImage from "../../assets/img/image_placeholder.jpg";
import defaultAvatar from "../../assets/img/placeholder.jpg";

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: null,
    };
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }
  handleImageChange(e) {
    this.props.handleImages(e);
  }
  handleSubmit(e) {
    e.preventDefault();
    // this.state.file is the file/image uploaded
    // in this function you can save the image (this.state.file) on form submit
    // you have to call it yourself
  }
  handleClick() {
    this.refs.fileInput.click();
  }

  render() {
    return (
      <div
        className="fileinput text-center"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <input
          type="file"
          onChange={this.handleImageChange}
          ref="fileInput"
          accept="image/*"
        />
        <Row style={{ display: "flex", width: "100%" }}>
          {this.props.images && this.props.images.length > 0 ? (
            <Row
              style={{
                display: "flex",
                justifyContent:
                  this.props.name == "icon" ? "flex-start" : "center",
              }}
            >
              {this.props.images.map((m, i) => {
                return (
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    md={4}
                    key={i}
                  >
                    <img
                      src={m}
                      alt="..."
                      width="100%"
                      height="100%"
                      style={{ marginBottom: "10px" }}
                    />
                    <Button
                      color={this.props.removeBtnColor}
                      className="btn-simple"
                      onClick={() => this.props.handleRemove(m)}
                      disabled={this.props.disabled}
                      style={{
                        color: "#FD1C03",
                        borderColor: "#FD1C03",
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 155,
                        height: 35,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <i className="fa fa-times"></i>{" "}
                        <span style={{ marginLeft: 5 }}>Remove</span>
                      </div>
                    </Button>
                  </Col>
                );
              })}
              {this.props.name !== "icon" && (
                <Col
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: 200,
                        height: 150,
                      }}
                    >
                      <i
                        className="fa fa-cloud-upload fa-5x"
                        style={{ background: "none", color: "rgb(0,228,228)" }}
                      />
                    </div>
                    <Button
                      color={this.props.addBtnColor}
                      className="btn-simple"
                      onClick={() => this.handleClick()}
                      disabled={this.props.disabled}
                      style={{
                        color: "rgb(248, 186, 0)",
                        borderColor: "rgb(248, 186, 0)",
                        fontSize: 12,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 155,
                        height: 35,
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <i className="fa fa-image"></i>{" "}
                        <span style={{ marginLeft: 5 }}>Select Images</span>
                      </div>
                    </Button>
                  </div>
                </Col>
              )}
            </Row>
          ) : (
            <div
              style={{
                width: "30%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {/* <img src={defaultImage} alt="..." /> */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: 200,
                  height: 150,
                }}
              >
                <i
                  className="fa fa-cloud-upload fa-5x"
                  style={{ background: "none", color: "rgb(0,228,228)" }}
                />
              </div>

              {(this.props.images === null ||
                (this.props.images && this.props.images.length == 0)) && (
                <Button
                  color={this.props.addBtnColor}
                  className="btn-simple"
                  onClick={() => this.handleClick()}
                  disabled={this.props.disabled}
                  style={{
                    color: "rgb(248, 186, 0)",
                    borderColor: "rgb(248, 186, 0)",
                    fontSize: 12,
                    display: "flex",
                    width: 155,
                    height: 35,
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <i className="fa fa-image"></i>{" "}
                    <span style={{ marginLeft: 5 }}>Select Images</span>
                  </div>
                </Button>
              )}
            </div>
          )}
        </Row>
      </div>
    );
  }
}

ImageUpload.defaultProps = {
  avatar: false,
  removeBtnClasses: "btn-round",
  removeBtnColor: "danger",
  addBtnClasses: "btn-round",
  addBtnColor: "primary",
  changeBtnClasses: "btn-round",
  changeBtnColor: "primary",
};

// ImageUpload.propTypes = {
//   avatar: PropTypes.bool,
//   removeBtnClasses: PropTypes.string,
//   removeBtnColor: PropTypes.oneOf([
//     "default",
//     "primary",
//     "secondary",
//     "success",
//     "info",
//     "warning",
//     "danger",
//     "link"
//   ]),
//   addBtnClasses: PropTypes.string,
//   addBtnColor: PropTypes.oneOf([
//     "default",
//     "primary",
//     "secondary",
//     "success",
//     "info",
//     "warning",
//     "danger",
//     "link"
//   ]),
//   changeBtnClasses: PropTypes.string,
//   changeBtnColor: PropTypes.oneOf([
//     "default",
//     "primary",
//     "secondary",
//     "success",
//     "info",
//     "warning",
//     "danger",
//     "link"
//   ])
// };

export default ImageUpload;
