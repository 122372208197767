import React, { Component } from "react";
import ContentUploadForm from "../views/ContentUploadForm";
import { connect } from "react-redux";
import * as actions from "../store/actions/spinner";
import {
  postData,
  closeModal,
  putData,
  fetchData,
  getDocumentById,
  approve,
  dismissCmsError,
} from "../store/actions/cms";
import { fetchMetaData } from "../store/actions/meta";

class ContentUploadFormContainer extends Component {
  componentDidMount() {
    this.props.fetchMetaData();
  }
  render() {
    return <ContentUploadForm {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  spinner: state.spinner,
  meta: state.meta,
  cms: state.cms,
});

const mapDispatchToProps = (dispatch) => ({
  showIconSpinner: (id) => dispatch(actions.showIconSpinner(id)),
  hideIconSpinner: (id) => dispatch(actions.hideIconSpinner(id)),
  postData: (d, f) => dispatch(postData(d, f)),
  putData: (d, f) => dispatch(putData(d, f)),
  hideModal: () => dispatch(closeModal()),
  fetchData: () => dispatch(fetchData()),
  getDocumentById: (id) => dispatch(getDocumentById(id)),
  fetchMetaData: () => dispatch(fetchMetaData()),
  approve: (id, type, path) => dispatch(approve(id, type, path)),
  dismissCmsError: () => dispatch(dismissCmsError()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContentUploadFormContainer);
