// // import React, { useEffect } from 'react';
// // import { connect } from 'react-redux';
// // import { restoreToken } from '../store/actions/auth';
// // import { Switch, Route, Redirect } from 'react-router-dom';
// // import LoginContainer from './Login';
// // import ContentUploadFormContainer from './ContentUploadForm';
// // import ContentTableContainer from './ContentTable';
// // import PreviewContainer from './Preview';

// // const AppContainer = (props) => {
// // 	useEffect(() => {
// // 		const fetchToken = async () => {
// // 			let userToken = await localStorage.getItem('x-auth');
// // 			let role = JSON.parse(await localStorage.getItem('role'));

// // 			try {
// // 				userToken = JSON.parse(await localStorage.getItem('x-auth'))
// // 					? JSON.parse(await localStorage.getItem('x-auth'))
// // 					: null;
// // 				props.restoreToken(userToken, role);
// // 				if (userToken == null) {
// // 					console.log('hello');
// // 					props.hist.push('/login');
// // 				}
// // 			} catch (e) {
// // 				// Restoring token failed
// // 			}
// // 		};
// // 		fetchToken();
// // 	}, []);
// // 	return (
// // 		<Switch>
// // 			<Route path="/login" exact component={LoginContainer} />

// // 			<Route path="/" exact component={ContentTableContainer} />
// // 			<Route path="/:model" exact component={ContentTableContainer} />
// // 			<Route path="/:model/add" exact component={ContentUploadFormContainer} />
// // 			<Route path="/:model/edit/:id" exact component={ContentUploadFormContainer} />
// // 			<Route path="/:model/view/:id" exact component={ContentUploadFormContainer} />
// // 			<Route path="/:model/preview" exact component={PreviewContainer} />
// // 			<Redirect from="/" to="/:model" />
// // 		</Switch>
// // 	);
// // };

// // const mapStateToProps = (state) => ({
// // 	auth: state.auth,
// // });

// // const mapDispatchToProps = (dispatch) => ({
// // 	restoreToken: (token, role) => dispatch(restoreToken(token, role)),
// // });

// // export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);

// // {
// // 	/* {routes.map((route) => {
// // 				return (
// // 					<Route
// // 						path={route.path}
// // 						render={({ match: { url } }) => (
// // 							<>
// // 								<Route path={`${url}`} exact component={ContentTableContainer} />
// // 								<Route path={`${url}/add`} exact component={ContentUploadFormContainer} />
// // 								<Route path={`${url}/edit/:id`} exact component={ContentUploadFormContainer} />
// // 								<Route path={`${url}/view/:id`} exact component={ContentUploadFormContainer} />
// // 								<Route path={`${url}/preview`} exact component={PreviewContainer} />
// // 							</>
// // 						)}
// // 					/>
// // 				);
// // 			})}
// // 			<Route path="/login" exact>
// // 				<LoginContainer {...props} />
// // 			</Route> */
// // }

// /*!

// =========================================================
// * Black Dashboard PRO React - v1.1.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
// * Copyright 2020 Creative Tim (https://www.creative-tim.com)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
// import React from 'react';
// import { Route, Switch, Redirect } from 'react-router-dom';
// // javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from 'perfect-scrollbar';
// // react plugin for creating notifications over the dashboard

// // core components
// import Navbar from '../components/Navbar';
// import Sidebar from '../components/Sidebar/Sidebar';

// import routes from '../routes';
// import LoginContainer from './Login';
// import ContentUploadFormContainer from './ContentUploadForm';
// import ContentTableContainer from './ContentTable';
// import PreviewContainer from './Preview';

// // import logo from "../../assets/img/react-logo.png";

// var ps;

// class App extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			activeColor: 'blue',
// 			sidebarMini: true,
// 			opacity: 0,
// 			sidebarOpened: false,
// 		};
// 	}
// 	componentDidMount() {
// 		if (navigator.platform.indexOf('Win') > -1) {
// 			document.documentElement.classList.add('perfect-scrollbar-on');
// 			document.documentElement.classList.remove('perfect-scrollbar-off');
// 			ps = new PerfectScrollbar(this.refs.mainPanel);
// 			this.refs.mainPanel.addEventListener('ps-scroll-y', this.showNavbarButton);
// 			let tables = document.querySelectorAll('.table-responsive');
// 			for (let i = 0; i < tables.length; i++) {
// 				ps = new PerfectScrollbar(tables[i]);
// 			}
// 		}
// 		window.addEventListener('scroll', this.showNavbarButton);
// 	}
// 	componentWillUnmount() {
// 		if (navigator.platform.indexOf('Win') > -1) {
// 			ps.destroy();
// 			document.documentElement.classList.add('perfect-scrollbar-off');
// 			document.documentElement.classList.remove('perfect-scrollbar-on');
// 			this.refs.mainPanel.removeEventListener('ps-scroll-y', this.showNavbarButton);
// 		}
// 		window.removeEventListener('scroll', this.showNavbarButton);
// 	}
// 	componentDidUpdate(e) {
// 		if (e.location.pathname !== e.history.location.pathname) {
// 			if (navigator.platform.indexOf('Win') > -1) {
// 				let tables = document.querySelectorAll('.table-responsive');
// 				for (let i = 0; i < tables.length; i++) {
// 					ps = new PerfectScrollbar(tables[i]);
// 				}
// 			}
// 			document.documentElement.scrollTop = 0;
// 			document.scrollingElement.scrollTop = 0;
// 			this.refs.mainPanel.scrollTop = 0;
// 		}
// 	}
// 	showNavbarButton = () => {
// 		if (
// 			document.documentElement.scrollTop > 50 ||
// 			document.scrollingElement.scrollTop > 50 ||
// 			this.refs.mainPanel.scrollTop > 50
// 		) {
// 			this.setState({ opacity: 1 });
// 		} else if (
// 			document.documentElement.scrollTop <= 50 ||
// 			document.scrollingElement.scrollTop <= 50 ||
// 			this.refs.mainPanel.scrollTop <= 50
// 		) {
// 			this.setState({ opacity: 0 });
// 		}
// 	};
// 	getRoutes = (routes) => {
// 		return routes.map((prop, key) => {
// 			if (prop.collapse) {
// 				return this.getRoutes(prop.views);
// 			}
// 			if (prop.layout === '/admin') {
// 				return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
// 			} else {
// 				return null;
// 			}
// 		});
// 	};
// 	getActiveRoute = (routes) => {
// 		let activeRoute = 'Default Brand Text';
// 		for (let i = 0; i < routes.length; i++) {
// 			if (routes[i].collapse) {
// 				let collapseActiveRoute = this.getActiveRoute(routes[i].views);
// 				if (collapseActiveRoute !== activeRoute) {
// 					return collapseActiveRoute;
// 				}
// 			} else {
// 				if (window.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
// 					return routes[i].name;
// 				}
// 			}
// 		}
// 		return activeRoute;
// 	};
// 	handleActiveClick = (color) => {
// 		this.setState({ activeColor: color });
// 	};
// 	handleMiniClick = () => {
// 		if (document.body.classList.contains('sidebar-mini')) {
// 			this.setState({ sidebarMini: false });
// 		} else {
// 			this.setState({ sidebarMini: true });
// 		}
// 		document.body.classList.toggle('sidebar-mini');
// 	};
// 	toggleSidebar = () => {
// 		this.setState({
// 			sidebarOpened: !this.state.sidebarOpened,
// 		});
// 		document.documentElement.classList.toggle('nav-open');
// 	};
// 	closeSidebar = () => {
// 		this.setState({
// 			sidebarOpened: false,
// 		});
// 		document.documentElement.classList.remove('nav-open');
// 	};
// 	render() {
// 		return (
// 			<div className="wrapper" style={{ backgroundColor: '#19191A' }}>
// 				{/* <div className="rna-container">
//           <NotificationAlert ref="notificationAlert" />
//         </div> */}
// 				<div
// 					className="navbar-minimize-fixed"
// 					style={{ opacity: this.state.opacity }}
// 					style={{ backgroundColor: '#19191A' }}
// 				>
// 					<button className="minimize-sidebar btn btn-link btn-just-icon" onClick={this.handleMiniClick}>
// 						<i className="tim-icons icon-double-right visible-on-sidebar-regular text-muted" />
// 						<i className="tim-icons icon-double-left visible-on-sidebar-mini text-muted" />
// 					</button>
// 				</div>
// 				<Sidebar
// 					{...this.props}
// 					routes={routes}
// 					activeColor={this.state.activeColor}
// 					logo={{
// 						outterLink: null,
// 						text: 'VISION ANALYTICS',
// 						imgSrc: logo,
// 					}}
// 					closeSidebar={this.closeSidebar}
// 				/>
// 				<div
// 					className="main-panel"
// 					ref="mainPanel"
// 					data={this.state.activeColor}
// 					style={{ backgroundColor: '#19191A' }}
// 				>
// 					<Navbar
// 						{...this.props}
// 						handleMiniClick={this.handleMiniClick}
// 						brandText={this.getActiveRoute(routes)}
// 						sidebarOpened={this.state.sidebarOpened}
// 						toggleSidebar={this.toggleSidebar}
// 					/>
// 					<Switch>
// 						<Route path="/login" exact component={LoginContainer} />
// 						<Route path="/" exact component={ContentTableContainer} />
// 						<Route path="/:model" exact component={ContentTableContainer} />
// 						<Route path="/:model/add" exact component={ContentUploadFormContainer} />
// 						<Route path="/:model/edit/:id" exact component={ContentUploadFormContainer} />
// 						<Route path="/:model/view/:id" exact component={ContentUploadFormContainer} />
// 						<Route path="/:model/preview" exact component={PreviewContainer} />
// 						<Redirect from="/" to="/:model" />
// 					</Switch>
// 					{/* {// we don't want the Footer to be rendered on full screen maps page
//           this.props.location.pathname.indexOf("full-screen-map") !==
//           -1 ? null : (
//             <Footer fluid />
//           )} */}
// 				</div>
// 			</div>
// 		);
// 	}
// }

// export default App;

import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";

import AdminLayout from "../layouts/Admin/Admin";
import { connect } from "react-redux";
import { restoreToken } from "../store/actions/auth";
import { fetchMetaData } from "../store/actions/meta";

import LoginContainer from "./Login";

const App = (props) => {
  useEffect(() => {
    const fetchToken = async () => {
      let userToken = await localStorage.getItem("x-auth");
      let role = JSON.parse(await localStorage.getItem("role"));
      try {
        userToken = JSON.parse(await localStorage.getItem("x-auth"))
          ? JSON.parse(await localStorage.getItem("x-auth"))
          : null;
        props.restoreToken(userToken, role);
        if (userToken == null) {
          props.hist.push("/login");
        }
      } catch (e) {
        // Restoring token failed
      }
    };
    fetchToken();
  }, [props.auth.userToken]);

  return (
    <div className="wrapper" style={{ backgroundColor: "#19191A" }}>
      <Switch>
        <Route exact path="/login" component={LoginContainer} />
        <Route path="/" render={(props) => <AdminLayout {...props} />} />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  restoreToken: (token, role) => dispatch(restoreToken(token, role)),
  fetchMetaData: () => dispatch(fetchMetaData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
