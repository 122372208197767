const INITIAL_STATE = {
  loadingFeatures: true,
  loadingFeature: true,
  features_data: [],
  feature: null,
  error: false,
  error_msg: null,
  uploading: false,
  showModal: false,
};

export const features = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_FEATURES_START":
      return {
        ...state,
        loadingFeatures: true,
      };
    case "FETCH_FEATURES_SUCCESS":
      return {
        ...state,
        loadingFeatures: false,
        features_data: action.payload,
      };
    case "FETCH_FEATURES_BY_ID_START":
      return {
        ...state,
        loadingFeature: true,
      };
    case "FETCH_FEATURE_BY_ID_SUCCESS":
      return {
        ...state,
        loadingFeature: false,
        feature: action.payload.features,
      };
    case "ON_FEATURES_ERROR":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        loadingFeatures: false,
        loadingFeature: false,
        uploading: false,
        showModal: false,
      };
    case "DISMISS_FEATURES_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "CREATE_FEATURE_START":
      return {
        ...state,
        uploading: true,
        showModal: true,
      };
    case "CREATE_FEATURE_SUCCESS":
      return {
        ...state,
        uploading: false,
      };
    case "CLOSE_FEATURE_MODAL":
      return {
        ...state,
        showModal: false,
      };
    default:
      return state;
  }
};
