export const showIconSpinner = (name) => {
  return {
    type: "SHOW_ICON_SPINNER",
    payload: name,
  };
};

export const hideIconSpinner = (name) => {
  return {
    type: "HIDE_ICON_SPINNER",
    payload: name,
  };
};
