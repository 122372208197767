import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  FormGroup,
  Col,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import "../styles/featureMapping.css";

const customStyles = {
  singleValue: (provided, state) => ({
    ...provided,
    color: "red",
  }),
  option: (provided, state) => ({
    ...provided,
    color: "red",
  }),
  input: (provided) => ({
    ...provided,
    color: "white",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: "red",
  }),
  container: (provided, state) => ({
    ...provided,
    color: "red",
  }),
};

const FeatureModel = (props) => {
  const [model, setModel] = useState();
  const [make, setMake] = useState();
  const [variant, setVariant] = useState();
  const [feature, setFeature] = useState();
  const [modelData, setModelData] = useState();
  const [variantsData, setVariantsData] = useState();
  const [featuresData, setFeatureData] = useState();
  const [makesData, setMakesData] = useState();
  const [canUpdateValue, setCanUpdateValue] = useState(false);

  useEffect(() => {
    setMakesData(props.makes);
  }, [props.makes]);

  useEffect(() => {
    if (props.source && props.features && props.SourcefeatureId) {
      // alert("HI");
      const {
        make,
        make_id,
        model,
        model_id,
        variant,
        variant_id,
        part,
        part_id,
      } = props.features.find((f) => f.id === props.SourcefeatureId);
      setMake({ value: make_id, label: make });
      setFeature({ value: part_id, label: part });
      setVariant({ value: variant_id, label: variant });
      setModel({ value: model_id, label: model });
      setCanUpdateValue(true);
    } else {
      setMake();
      setFeature();
      setVariant();
      setModel();
    }
  }, [props.SourcefeatureId]);

  useEffect(() => {
    if (canUpdateValue === true && props.source) {
      // alert("hi");
      updateFieldsData.make(make, props.features);
      updateFieldsData.model(model, props.features, make);
      updateFieldsData.feature(feature, props.features, make, model);
      updateFieldsData.variant(variant, props.features, make, model, feature);
    }
  }, [canUpdateValue]);

  useEffect(() => {
    if (props.editFeature || props.sourceFeatures) {
      if (props.SourcefeatureId && props.source) {
        if (props.SourcefeatureId) {
          if (props.edit === false && props.sourceFeatures) {
            const {
              make,
              make_id,
              model,
              model_id,
              variant,
              variant_id,
              part,
              part_id,
            } = props.editFeature;
            setMake({ value: make_id, label: make });
            setFeature({ value: part_id, label: part });
            setVariant({ value: variant_id, label: variant });
            setModel({ value: model_id, label: model });
            setCanUpdateValue(true);
          } else {
            if (props.features.length > 0) {
              const {
                make,
                make_id,
                model,
                model_id,
                variant,
                variant_id,
                part,
                part_id,
              } = props.features.find((f) => f.id === props.SourcefeatureId);
              setMake({ value: make_id, label: make });
              setFeature({ value: part_id, label: part });
              setVariant({ value: variant_id, label: variant });
              setModel({ value: model_id, label: model });
              setCanUpdateValue(true);
            }
          }
        }
      } else {
        if (props.source === false) {
          const { make, model, variant, feature } = props.editFeature;
          setMake({ label: make });
          setFeature({ label: feature });
          setVariant({ label: variant });
          setModel({ label: model });
        }
      }
    }
  }, [props.editFeature, props.source]);

  // useEffect(() => {}, []);

  const updateFieldsData = {
    make: (e, features) => {
      let selectedMakeId = e.value;
      let models = Array.from(
        new Set(
          features
            .filter((f) => f.make_id === selectedMakeId)
            .map((f) => f.model)
        )
      );

      let unique_models = [];
      models.map((model) => {
        let modelId = features.find(
          (f) => f.make_id === selectedMakeId && f.model === model
        ).model_id;
        unique_models.push({
          value: modelId,
          label: model,
        });
      });

      setMake(e);
      setModelData(unique_models);
    },
    model: (e, features, make) => {
      let selectedMakeId = make.value;
      let selectedModelId = e.value;

      let parts = Array.from(
        new Set(
          features
            .filter(
              (f) =>
                f.make_id === selectedMakeId && f.model_id === selectedModelId
            )
            .map((f) => f.part)
        )
      );

      let unique_parts = [];
      parts.map((part) => {
        let partId = features.find(
          (f) =>
            f.make_id === selectedMakeId &&
            f.model_id === selectedModelId &&
            f.part === part
        ).part_id;
        unique_parts.push({
          value: partId,
          label: part,
        });
      });
      setModel(e);
      setFeatureData(unique_parts);
      setVariantsData([]);
      setVariant(null);
      setFeature(null);
    },
    feature: (e, features, make, model) => {
      let selectedMakeId = make.value;
      let selectedModelId = model.value;
      let selectedPartId = e.value;
      let variants = Array.from(
        new Set(
          features
            .filter(
              (f) =>
                f.make_id === selectedMakeId &&
                f.model_id === selectedModelId &&
                f.part_id === selectedPartId
            )
            .map((f) => f.variant)
        )
      );

      let unique_variants = [];
      variants.map((variant) => {
        let variantId = features.find(
          (f) =>
            f.make_id === selectedMakeId &&
            f.model_id === selectedModelId &&
            f.variant === variant
        ).variant_id;
        unique_variants.push({
          value: variantId,
          label: variant,
        });
      });

      setFeature(e);
      setVariantsData(unique_variants);
      setVariant(null);
    },
    variant: (e, features, make, model, feature) => {
      setVariant(e);
      getFeatureId(e, make, model, feature);
    },
  };

  const getFeatureId = (e, make, model, feature) => {
    let NewFeature = props.features.filter(
      (feat, index) =>
        feat.make_id === make.value &&
        feat.model_id === model.value &&
        feat.part_id === feature.value &&
        feat.variant_id === e.value
    );

    if (props.source !== true) {
      props.updateTargetFeature(NewFeature[0].id);
    } else {
      props.updateSourceFeature(NewFeature[0].id, props.index);
    }
  };

  const onChange = (e, key) => {
    let features = props.features;

    updateFieldsData[key](e, features, make, model, feature);
  };

  return (
    <Form
      action="#"
      style={{
        // border: "2px solid black",
        margin: "10px",
        backgroundColor: "#292929",
      }}
    >
      {props.source === true ? (
        <Row>
          <Col style={{ textAlign: "end" }}>
            <Button
              className="btn-link btn-icon btn-neutral btn-icon"
              color="danger"
              id="tooltip707467505"
              size="sm"
              title="Remove"
              type="button"
              disabled={props.view}
              style={{ padding: 10 }}
              onClick={() => {
                props.handleFeatureRemove(props.index);
              }}
            >
              <i
                className="tim-icons icon-simple-remove"
                style={{ color: "red" }}
              />
            </Button>
          </Col>
        </Row>
      ) : null}
      <Row style={{ justifyContent: "center", padding: 10 }}>
        <Col md="5">
          <label>Make</label>
          <FormGroup>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              name="singleSelect"
              value={make}
              onChange={(e) => onChange(e, "make")}
              options={makesData}
              placeholder="Select Make"
              isDisabled={props.disabled}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
        <Col md="5">
          <label>Model</label>
          <FormGroup>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              name="singleSelect"
              value={model}
              onChange={(e) => onChange(e, "model")}
              options={modelData}
              placeholder="Select Model"
              isDisabled={props.disabled}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row style={{ justifyContent: "center", padding: 5 }}>
        <Col md="5">
          <label>Feature</label>
          <FormGroup>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              name="singleSelect"
              value={feature}
              onChange={(value) => onChange(value, "feature")}
              options={featuresData}
              placeholder="Select Feature"
              isDisabled={props.disabled}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
        <Col md="5">
          <label>Variant</label>
          <FormGroup>
            <Select
              className="react-select info"
              classNamePrefix="react-select"
              name="singleSelect"
              value={variant}
              key={variant}
              onChange={(value) => onChange(value, "variant")}
              options={variantsData}
              placeholder="Select Variant"
              // isMulti
              // isDisabled={
              //   this.state.make == null ||
              //   this.state.model == null ||
              //   this.state.feature == null ||
              //   edit ||
              //   view
              // }
              isDisabled={props.disabled}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};
export default FeatureModel;
