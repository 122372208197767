import React, { useEffect } from "react";
import FeaturesMappingView from "../views/FeaturesMapping";
import * as actions from "../store/actions/spinner";
import { fetchMetaData } from "../store/actions/meta";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Spinner } from "reactstrap";

const FeaturesMapping = (props) => {
  const Dispatch = useDispatch();
  const { error, error_msg, features, loadingMeta, makes } = useSelector(
    (state) => state.meta
  );
  useEffect(() => {
    Dispatch(fetchMetaData());
  }, []);

  return !loadingMeta ? (
    <FeaturesMappingView
      features={features}
      makes={makes}
      {...props}
      edit={props.location.state && props.location.state.edit ? true : false}
      view={props.location.state && props.location.state.view ? true : false}
    />
  ) : (
    <div
      className="content"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Spinner size="large" />
    </div>
  );
};

export default FeaturesMapping;
