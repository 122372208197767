import React from "react";
import FeaturesTable from "../views/FeaturesTable";
import { connect } from "react-redux";
import { fetchMetaData } from "../store/actions/meta";
import {
  fetchFeatures,
  dismissFeaturesError,
  deleteFeature,
} from "../store/actions/features";
import { deleteFeatureMapping } from "../store/actions/featureMapping";

const FeatureTableContainer = (props) => {
  React.useEffect(() => {
    // let pathname = props.history.location.pathname;
    // let model_id = props.meta.models
    // 	? props.meta.models.filter((m) => `/${m.label.toLowerCase()}` === pathname)
    // 	: 1;
    props.fetchMetaData();
    props.fetchFeatures();
    //props.fetchData(model_id);
  }, []);

  return <FeaturesTable {...props} />;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  cms: state.cms,
  meta: state.meta,
  features: state.features,
});

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMetaData: () => dispatch(fetchMetaData()),
    fetchFeatures: () => dispatch(fetchFeatures()),
    dismissFeaturesError: () => dispatch(dismissFeaturesError()),
    deleteFeature: (id) => dispatch(deleteFeature(id)),
    deleteFeatureMapping: (id) => dispatch(deleteFeatureMapping(id)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeatureTableContainer);
