import React from "react";
import CreateFeature from "../views/CreateFeature";
import { connect } from "react-redux";
import { createPart, fetchParts } from "../store/actions/parts";
import {
  createFeature,
  dismissFeaturesError,
  closeFeatureModal,
} from "../store/actions/features";

const CreateFeatureContainer = (props) => {
  React.useEffect(() => {
    props.fetchParts();
  }, []);

  return <CreateFeature {...props} />;
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  spinner: state.spinner,
  meta: state.meta,
  cms: state.cms,
  parts: state.parts,
  features: state.features,
});

const mapDispatchToProps = (dispatch) => ({
  createPart: (data) => dispatch(createPart(data)),
  fetchParts: () => dispatch(fetchParts()),
  createFeature: (data) => dispatch(createFeature(data)),
  dismissFeaturesError: () => dispatch(dismissFeaturesError()),
  closeFeatureModal: () => dispatch(closeFeatureModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateFeatureContainer);
