/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import XANE_AI from "../assets/img/finalLogoXaneAi.png";
import MARUTI_SUZUKI from "../assets/img/MSIL_Logo.png";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      size: {},
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.splayWindowSize);
  }

  splayWindowSize = () => {
    // Get width and height of the window excluding scrollbars
    var w = document.documentElement.clientWidth;
    var h = document.documentElement.clientHeight;

    // Display result inside a div element
    this.setState({ size: { width: w, height: h } }, () => {});
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  render() {
    const { width, height } = this.state.size;
    return (
      // <Navbar
      //   className="navbar-absolute fixed-top"
      //   expand="lg"
      //   style={{ backgroundColor: "white" }}
      // >
      //   <Container fluid>
      //     <div
      //       style={{
      //         justifyContent: "flex-start",
      //         paddingLeft: "10px",
      //       }}
      //     >
      //       <img src={XANE_AI} width="25%" height="10%" />
      //     </div>
      //     {/* <div className="navbar-wrapper">
      //       <NavbarBrand  onClick={(e) => e.preventDefault()}>
      //         <img src={MARUTI_SUZUKI} width="25%" height="10%" />
      //       </NavbarBrand>
      //     </div> */}

      //     <div
      //       style={{
      //         justifyContent: "flex-end",

      //         paddingRight: "10px",
      //       }}
      //     >
      //       <img src={MARUTI_SUZUKI} width="30%" height="30%" />
      //     </div>
      //     {/* <div className="navbar-wrapper">
      //       <NavbarBrand  onClick={(e) => e.preventDefault()}>
      //         <img src={MARUTI_SUZUKI} width="25%" height="10%" />
      //       </NavbarBrand>
      //     </div> */}
      //   </Container>
      // </Navbar>
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <Container fluid style={{ position: "relative" }}>
          <div className="navbar-wrapper">
            <NavbarBrand href="#pablo" onClick={(e) => e.preventDefault()}>
              <img
                src={XANE_AI}
                width={width < 320 ? "30%" : "15%"}
                height={width < 320 ? "20%" : "10%"}
              />
            </NavbarBrand>

            <img
              src={MARUTI_SUZUKI}
              width="25%"
              style={{
                position: "absolute",
                right: 10,
                zIndex: 999,
                top: width < 500 ? 20 : -20,
              }}
            />
          </div>
        </Container>
      </Navbar>
    );
  }
}

export default AuthNavbar;
