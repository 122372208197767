const INITIALSTATE = {
  loadingIcon: false,
  loadingillustrationsTop: false,
  loadingillustrationsMiddle: false,
  loadingillustrationsBottom: false,
  loadingVideo: false,
};

export const spinner = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case "SHOW_ICON_SPINNER":
      return {
        ...state,
        [action.payload]: true,
      };

    case "HIDE_ICON_SPINNER":
      return {
        ...state,
        [action.payload]: false,
      };

    default:
      return state;
  }
};
