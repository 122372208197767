import { requestApi } from "../../config/apiHandler";

export const restoreToken = (token, role) => {
  return {
    type: "RESTORE_TOKEN",
    payload: {
      token: token,
      role: role,
    },
  };
};

export const signInSuccess = (token, role) => {
  return {
    type: "SIGN_IN_SUCCESS",
    payload: {
      token: token,
      role: role,
    },
  };
};

export const signInStarted = () => {
  return {
    type: "SIGN_IN_STARTED",
  };
};

export const signIn = (email, password) => {
  return async (dispatch) => {
    dispatch(signInStarted());

    let token = null;
    let role = null;
    let params = {};
    params.email = email;
    params.password = password;
    const response = await requestApi("/auths/login", "POST", params);

    if (response.code == "success") {
      token = response.data.session.token;
      role = response.data.user.cms_role;
      localStorage.setItem("x-auth", JSON.stringify(token));
      localStorage.setItem("role", JSON.stringify(role));
      return dispatch(signInSuccess(token, role));
    } else {
      return dispatch(signInFailure(response.message));
    }
    // await localStorage.setItem("x-auth", JSON.stringify(token));
    // dispatch(signInSuccess(token));
  };
};

export const signInFailure = (error) => {
  return {
    type: "SIGN_IN_FAILURE",
    payload: error,
  };
};

export const signOut = () => {
  return async (dispatch) => {
    const response = await requestApi("/auths/logout", "PUT", {});
    try {
      if (response.code == "success" || response.code == "authn_fail") {
        await localStorage.removeItem("x-auth");
        await localStorage.removeItem("role");
        return dispatch({ type: "SIGN_OUT" });
      }
    } catch (e) {
      console.error(e);
    }
    // await localStorage.removeItem("x-auth");
    // dispatch({ type: "SIGN_OUT" });
  };
};

export const signedOutSuccess = () => {
  return {
    type: "LOGGED_OUT",
  };
};

export const dismissError = () => {
  return {
    type: "DISMISS_ERROR",
  };
};
