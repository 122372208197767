const INITIALSTATE = {
  loading: false,
  loadingFullData: false,
  fullData: [],
  documents: [],
  error: false,
  error_msg: null,
  uploading: false,
  showModal: false,
  fetchingDocument: false,
  document: [],
  approve: false,
};

export const cms = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case "FETCH_DATA_START":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_DATA_SUCCESS":
      return {
        ...state,
        documents: action.payload,
        loading: false,
      };
    case "FETCH_DATA_FULL_START":
      return {
        ...state,
        loadingFullData: true,
      };
    case "FETCH_DATA_FULL_SUCCESS":
      return {
        ...state,
        fullData: action.payload,
        loadingFullData: false,
      };
    case "ON_ERROR":
      return {
        ...state,
        error: true,
        error_msg: action.payload,
        uploading: false,
        showModal: false,
      };
    case "DISMISS_CMS_ERROR":
      return {
        ...state,
        error: false,
        error_msg: null,
      };
    case "POST_DATA_START":
      return {
        ...state,
        uploading: true,
        showModal: true,
      };
    case "POST_DATA_SUCCESS":
      return {
        ...state,
        uploading: false,
      };
    case "PUT_DATA_START":
      return {
        ...state,
        uploading: true,
        showModal: true,
      };
    case "PUT_DATA_SUCCESS":
      return {
        ...state,
        uploading: false,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        showModal: false,
      };
    case "GET_DOCUMENT_BY_ID_START":
      return {
        ...state,
        fetchingDocument: true,
      };
    case "GET_DOCUMENT_BY_ID_SUCCESS":
      return {
        ...state,
        document: action.payload,
        fetchingDocument: false,
      };
    case "APPROVE_START":
      return {
        ...state,
        showModal: true,
        approve: false,
      };
    case "APPROVE_SUCCESS":
      return {
        ...state,
        approve: true,
      };

    default:
      return state;
  }
};
