// // /*!

// // =========================================================
// // * Black Dashboard PRO React - v1.1.0
// // =========================================================

// // * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
// // * Copyright 2020 Creative Tim (https://www.creative-tim.com)

// // * Coded by Creative Tim

// // =========================================================

// // * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// // */
// // import React from 'react';
// // // nodejs library that concatenates classes
// // import classNames from 'classnames';
// // import './styles.css';
// // // reactstrap components
// // import { NavbarBrand, Navbar, Container } from 'reactstrap';
// // import Xane_A from '../assets/img/xane-icon.png';
// // import { connect } from 'react-redux';
// // import { signOut } from '../store/actions/auth';
// // import Suzuki_S from '../assets/img/suzuki_s.png';

// // class AdminNavbar extends React.Component {
// // 	constructor(props) {
// // 		super(props);
// // 		this.state = {
// // 			collapseOpen: false,
// // 			modalSearch: false,
// // 			color: 'navbar-transparent',
// // 		};
// // 	}
// // 	componentDidMount() {
// // 		window.addEventListener('resize', this.updateColor);
// // 	}
// // 	componentWillUnmount() {
// // 		window.removeEventListener('resize', this.updateColor);
// // 	}
// // 	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
// // 	updateColor = () => {
// // 		if (window.innerWidth < 993 && this.state.collapseOpen) {
// // 			this.setState({
// // 				color: 'bg-white',
// // 			});
// // 		} else {
// // 			this.setState({
// // 				color: 'navbar-transparent',
// // 			});
// // 		}
// // 	};
// // 	// this function opens and closes the collapse on small devices
// // 	toggleCollapse = () => {
// // 		if (this.state.collapseOpen) {
// // 			this.setState({
// // 				color: 'navbar-transparent',
// // 			});
// // 		} else {
// // 			this.setState({
// // 				color: 'bg-white',
// // 			});
// // 		}
// // 		this.setState({
// // 			collapseOpen: !this.state.collapseOpen,
// // 		});
// // 	};
// // 	// this function is to open the Search modal
// // 	toggleModalSearch = () => {
// // 		this.setState({
// // 			modalSearch: !this.state.modalSearch,
// // 		});
// // 	};
// // 	render() {
// // 		const { pathname } = this.props.history.location;
// // 		return (
// // 			<>
// // 				<Navbar expand="lg" style={{ backgroundColor: '#303030' }}>
// // 					<Container fluid style={{ display: 'flex' }}>
// // 						<div className="navbar-wrapper">
// // 							<NavbarBrand
// // 								onClick={() => {
// // 									this.props.history.push('/');
// // 								}}
// // 							>
// // 								<p style={{ fontWeight: 'bold' }}>
// // 									{window.screen.width > 450 && (
// // 										<img
// // 											onClick={() => {
// // 												this.props.history.push('/');
// // 											}}
// // 											src={Suzuki_S}
// // 											width={22}
// // 										/>
// // 									)}{' '}
// // 									<span style={{ marginLeft: 5 }}>Content Management System</span>
// // 								</p>
// // 							</NavbarBrand>
// // 						</div>
// // 						<div>
// // 							<a onClick={() => this.props.signOut()} title="Logout">
// // 								<i
// // 									className="fa fa-power-off fa-lg"
// // 									aria-hidden="true"
// // 									style={{ color: '#FF3300', marginRight: 5 }}
// // 								></i>
// // 							</a>{' '}
// // 						</div>
// // 					</Container>
// // 				</Navbar>
// // 			</>
// // 		);
// // 	}
// // }

// // const mapDispatchToProps = (dispatch) => ({
// // 	signOut: () => dispatch(signOut()),
// // });

// // export default connect(null, mapDispatchToProps)(AdminNavbar);
// /*!

// =========================================================
// * Black Dashboard PRO React - v1.1.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
// * Copyright 2020 Creative Tim (https://www.creative-tim.com)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
// import React from 'react';
// // nodejs library that concatenates classes
// import classNames from 'classnames';
// import Suzuki_S from '../assets/img/suzuki_s.png';
// // reactstrap components
// import { Button, NavbarBrand, Navbar, Container, UncontrolledTooltip } from 'reactstrap';

// import { connect } from 'react-redux';
// import { signOut } from '../store/actions/auth';

// class AdminNavbar extends React.Component {
// 	constructor(props) {
// 		super(props);
// 		this.state = {
// 			collapseOpen: false,
// 			modalSearch: false,
// 			color: 'navbar-transparent',
// 		};
// 	}
// 	componentDidMount() {
// 		window.addEventListener('resize', this.updateColor);
// 	}
// 	componentWillUnmount() {
// 		window.removeEventListener('resize', this.updateColor);
// 	}
// 	// function that adds color white/transparent to the navbar on resize (this is for the collapse)
// 	updateColor = () => {
// 		if (window.innerWidth < 993 && this.state.collapseOpen) {
// 			this.setState({
// 				color: 'bg-white',
// 			});
// 		} else {
// 			this.setState({
// 				color: 'navbar-transparent',
// 			});
// 		}
// 	};
// 	// this function opens and closes the collapse on small devices
// 	toggleCollapse = () => {
// 		if (this.state.collapseOpen) {
// 			this.setState({
// 				color: 'navbar-transparent',
// 			});
// 		} else {
// 			this.setState({
// 				color: 'bg-white',
// 			});
// 		}
// 		this.setState({
// 			collapseOpen: !this.state.collapseOpen,
// 		});
// 	};
// 	// this function is to open the Search modal
// 	toggleModalSearch = () => {
// 		this.setState({
// 			modalSearch: !this.state.modalSearch,
// 		});
// 	};
// 	render() {
// 		return (
// 			<>
// 				<Navbar
// 					// className={classNames("navbar-absolute", {
// 					//   [this.state.color]:
// 					//     this.props.location.pathname.indexOf("full-screen-map") === -1,
// 					// })}
// 					expand="lg"
// 					style={{ backgroundColor: '#303030', position: 'fixed' }}
// 				>
// 					<Container fluid>
// 						<div className="navbar-wrapper">
// 							<div className="navbar-minimize d-inline">
// 								{window.screen.width > 450 && <img src={Suzuki_S} width={30} />}{' '}
// 								<Button
// 									className="minimize-sidebar btn-just-icon"
// 									color="link"
// 									id="tooltip209599"
// 									onClick={this.props.handleMiniClick}
// 								>
// 									<i className="tim-icons icon-double-left visible-on-sidebar-regular" />
// 									<i className="tim-icons icon-double-right visible-on-sidebar-mini" />
// 								</Button>
// 								<UncontrolledTooltip delay={0} target="tooltip209599" placement="right">
// 									Sidebar toggle
// 								</UncontrolledTooltip>
// 							</div>
// 							<div
// 								className={classNames('navbar-toggle d-inline', {
// 									toggled: this.props.sidebarOpened,
// 								})}
// 							>
// 								<button className="navbar-toggler" type="button" onClick={this.props.toggleSidebar}>
// 									<span className="navbar-toggler-bar bar1" />
// 									<span className="navbar-toggler-bar bar2" />
// 									<span className="navbar-toggler-bar bar3" />
// 								</button>
// 							</div>
// 							<NavbarBrand
// 								href="#pablo"
// 								style={{ fontWeight: '600' }}
// 								onClick={(e) => e.preventDefault()}
// 							>
// 								Content Management System
// 							</NavbarBrand>
// 						</div>
// 						<div>
// 							<a onClick={() => this.props.signOut()}>
// 								<i
// 									className="fa fa-power-off fa-lg"
// 									aria-hidden="true"
// 									style={{ color: '#d13400', marginRight: 5 }}
// 								></i>
// 							</a>{' '}
// 						</div>
// 					</Container>
// 				</Navbar>
// 			</>
// 		);
// 	}
// }

// const mapStateToProps = (state) => ({});

// const mapDispatchToProps = (dispatch) => ({
// 	signOut: () => dispatch(signOut()),
// });

// export default connect(null, mapDispatchToProps)(AdminNavbar);

/*!

=========================================================
* Black Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import Suzuki_S from "../assets/img/suzuki_s.png";
// reactstrap components
import {
  Button,
  NavbarBrand,
  Navbar,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

import { connect } from "react-redux";
import { signOut } from "../store/actions/auth";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent",
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  // this function opens and closes the collapse on small devices
  toggleCollapse = () => {
    if (this.state.collapseOpen) {
      this.setState({
        color: "navbar-transparent",
      });
    } else {
      this.setState({
        color: "bg-white",
      });
    }
    this.setState({
      collapseOpen: !this.state.collapseOpen,
    });
  };
  // this function is to open the Search modal
  toggleModalSearch = () => {
    this.setState({
      modalSearch: !this.state.modalSearch,
    });
  };
  render() {
    return (
      <>
        <Navbar
          // className={classNames("navbar-absolute", {
          //   [this.state.color]:
          //     this.props.location.pathname.indexOf("full-screen-map") === -1,
          // })}
          expand="lg"
          style={{ backgroundColor: "#303030", position: "fixed" }}
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div className="navbar-minimize d-inline">
                {window.screen.width > 450 && <img src={Suzuki_S} width={30} />}{" "}
                <Button
                  className="minimize-sidebar btn-just-icon"
                  color="link"
                  id="tooltip209599"
                  onClick={this.props.handleMiniClick}
                >
                  <i className="tim-icons icon-double-left visible-on-sidebar-regular" />
                  <i className="tim-icons icon-double-right visible-on-sidebar-mini" />
                </Button>
                <UncontrolledTooltip
                  delay={0}
                  target="tooltip209599"
                  placement="right"
                >
                  Sidebar toggle
                </UncontrolledTooltip>
              </div>
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened,
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand
                href="#pablo"
                style={{ fontWeight: "600" }}
                onClick={(e) => e.preventDefault()}
              >
                {this.props.brandText}
              </NavbarBrand>
            </div>
            <div>
              <a onClick={() => this.props.signOut()}>
                <i
                  className="fa fa-power-off fa-lg"
                  aria-hidden="true"
                  style={{ color: "#d13400", marginRight: 5 }}
                ></i>
              </a>{" "}
            </div>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
});

export default connect(null, mapDispatchToProps)(AdminNavbar);
