import React from "react";
import { CSVLink } from "react-csv";

const encloseCsvContent = (data) => {
  data = data.replace(/"/g, '""');

  return data;
};

const DownloadCsv = ({ data, fullData }) => {
  let finalData;
  if (fullData) {
    let csvTemplateIds = [2, 3, 5, 6, 8, 10, 12, 14, 15]; // Only the text fields
    // CSV column names are
    // id,version,approved,make,model,variant,part,verbal,vision,critical,editor_first_name,editor_last_name,approver_first_name,approver_last_name,title,blurb,warning,caution,description_top,description_middle,description_bottom,chapter,related_features
    for (let i = 0; i < data.length; i++) {
      if (data[i].document_fields) {
        data[i].document_fields = data[i].document_fields.filter(
          (documentField) =>
            csvTemplateIds.includes(documentField.template_sequence)
        );
        data[i].document_fields.forEach((documentField) => {
          delete documentField.document_field_sequence;
          delete documentField.template_sequence;
          if (
            documentField.hasOwnProperty("related_features") &&
            documentField.related_features.hasOwnProperty("length") &&
            documentField.related_features.length !== 0
          ) {
            documentField.related_features = documentField.related_features
              .map((feature) => feature.part.replace(",", ""))
              .toString()
              .replace(",", ";");
          }
          data[i] = { ...data[i], ...documentField };
        });
        delete data[i].document_fields;
        // console.log(data[i]);
        let _data = data[i];
        data[i] = {};
        data[i].id = _data.hasOwnProperty("id") ? _data.id : "";
        data[i].version = _data.hasOwnProperty("version") ? _data.version : "";
        data[i].approved = _data.hasOwnProperty("approved")
          ? _data.approved
          : "";
        data[i].make = _data.hasOwnProperty("make") ? _data.make : "";
        data[i].model = _data.hasOwnProperty("model") ? _data.model : "";
        data[i].variant = _data.hasOwnProperty("variant") ? _data.variant : "";
        data[i].part = _data.hasOwnProperty("part") ? _data.part : "";
        data[i].verbal = _data.hasOwnProperty("verbal") ? _data.verbal : "";
        data[i].vision = _data.hasOwnProperty("vision") ? _data.vision : "";
        data[i].critical = _data.hasOwnProperty("critical")
          ? _data.critical
          : "";
        data[i].editor_first_name = _data.hasOwnProperty("editor_first_name")
          ? _data.editor_first_name
          : "";
        data[i].editor_last_name = _data.hasOwnProperty("editor_last_name")
          ? _data.editor_last_name
          : "";
        data[i].approver_first_name = _data.hasOwnProperty(
          "approver_first_name"
        )
          ? _data.approver_first_name
          : "";
        data[i].approver_last_name = _data.hasOwnProperty("approver_last_name")
          ? _data.approver_last_name
          : "";
        data[i].title = _data.hasOwnProperty("title")
          ? encloseCsvContent(_data.title)
          : "";
        data[i].blurb = _data.hasOwnProperty("blurb")
          ? encloseCsvContent(_data.blurb)
          : "";
        data[i].warning = _data.hasOwnProperty("warning")
          ? encloseCsvContent(_data.warning)
          : "";
        data[i].caution = _data.hasOwnProperty("caution")
          ? encloseCsvContent(_data.caution)
          : "";
        data[i].description_top = _data.hasOwnProperty("description_top")
          ? encloseCsvContent(_data.description_top)
          : "";
        data[i].description_middle = _data.hasOwnProperty("description_middle")
          ? encloseCsvContent(_data.description_middle)
          : "";
        data[i].description_bottom = _data.hasOwnProperty("description_bottom")
          ? encloseCsvContent(_data.description_bottom)
          : "";
        data[i].chapter = _data.hasOwnProperty("chapter")
          ? encloseCsvContent(_data.chapter)
          : "";
        data[i].related_features = _data.hasOwnProperty("related_features")
          ? _data.related_features
          : "";
      } else {
        data[i].title = "";
        data[i].blurb = "";
        data[i].warning = "";
        data[i].caution = "";
        data[i].description_top = "";
        data[i].description_middle = "";
        data[i].description_bottom = "";
        data[i].chapter = "";
      }
    }
  }
  const filteredData = data.map((item, index) => {
    return Object.keys(item)
      .filter((key) => !key.includes("actions"))
      .reduce((obj, key) => {
        obj[key] = item[key];
        return obj;
      }, {});
  });
  //   console.log(filteredData);

  return (
    <CSVLink
      type="button"
      className="btn btn-light"
      filename={`features_data.csv`}
      data={filteredData}
      // headers={this.state.headers}
      target="_blank"
      style={{ background: "rgb(50, 142, 182)" }}
    >
      <div className="download_style">
        <i className="fa fa-download" style={{ width: "100%" }} />
      </div>
    </CSVLink>
  );
};

export default DownloadCsv;
