import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";

import { cms } from "./reducers/cms";
import { authReducer } from "./reducers/auth";
import { spinner } from "./reducers/spinner";
import { meta } from "./reducers/meta";
import { features } from "./reducers/features";
import { parts } from "./reducers/parts";
import { featureMapping } from "./reducers/featureMapping";

const rootReducer = combineReducers({
  cms,
  auth: authReducer,
  spinner: spinner,
  meta: meta,
  features,
  parts,
  featureMapping,
});

const composeEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

export const configureStore = () => {
  return createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
};
