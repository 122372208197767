import React from "react";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Spinner } from "reactstrap";
const UploadAlert = (props) => {
  return (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={props.uploading ? "Submitting..." : "Submitted Successfully"}
      onConfirm={() => props.hideModal()}
      // onCancel={() => this.hideAlert()}
      confirmBtnBsStyle="default"
      btnSize=""
      showConfirm={props.uploading ? false : true}
    >
      {props.uploading ? (
        <Spinner size="large" />
      ) : (
        <i
          className="fa fa-check-circle fa-2x"
          aria-hidden="true"
          style={{ color: "seagreen", width: 24 }}
        ></i>
      )}
    </ReactBSAlert>
  );
};

export default UploadAlert;
