import React from "react";

export default function Cautions(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div style={{ padding: 10 }}>
        <i className="fa fa-arrow-left" aria-hidden="true"></i>{" "}
        <span style={{ marginLeft: 10, fontWeight: "bold" }}>Cautions</span>
      </div>
      <div style={{ fontWeight: "bold", padding: 10 }}>{props.title}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <p style={{ color: "ButtonText", padding: 10, whiteSpace: "pre-wrap" }}>
          {props.caution_text.length >= 1 ? (
            props.caution_text
          ) : (
            <div style={{ left: "33%", top: "50%", position: "absolute" }}>
              <p style={{ color: "ButtonText" }}>No Content Found</p>
            </div>
          )}
        </p>
      </div>
    </div>
  );
}
