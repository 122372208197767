import { requestApi } from "../../config/apiHandler";
import { signOut } from "../actions/auth";
import { store } from "../../index";
import { CognitoIdentityServiceProvider } from "aws-sdk";

export const fetchDataStart = () => {
  return {
    type: "FETCH_DATA_START",
  };
};
export const fetchDataFUllStart = () => {
  return {
    type: "FETCH_DATA_FULL_START",
  };
};

export const fetchData = (id) => {
  let model_id = JSON.stringify(id);

  return async (dispatch) => {
    dispatch(fetchDataStart());

    const response = await requestApi(
      `/documents?summary=1&model_id=${model_id}`,
      "GET",
      {}
    );
    if (response.code == "success") {
      dispatch({
        type: "FETCH_DATA_SUCCESS",
        payload: response.data.documents,
      });
    } else if (response.code == "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onError(response.message));
    }
  };
};

export const fetchFullData = (id) => {
  let model_id = JSON.stringify(id);
  return async (dispatch) => {
    dispatch(fetchDataFUllStart());

    // alert("hi");
    const response = await requestApi(
      `/documents?summary=1&full=1&model_id=${model_id}`,
      "GET",
      {}
    );

    if (response.code == "success") {
      dispatch({
        type: "FETCH_DATA_FULL_SUCCESS",
        payload: response.data.documents,
      });
    } else if (response.code == "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onError(response.message));
    }
  };
};

export const onError = (error) => {
  return {
    type: "ON_ERROR",
    payload: error,
  };
};

export const dismissCmsError = () => {
  return {
    type: "DISMISS_CMS_ERROR",
  };
};

export const postDataStart = () => {
  return {
    type: "POST_DATA_START",
  };
};

export const putDataStart = () => {
  return {
    type: "PUT_DATA_START",
  };
};

export const postData = (data, features) => {
  return async (dispatch) => {
    dispatch(postDataStart());
    let counter = 0;
    features.map(async (f, i, a) => {
      let params = JSON.parse(data);
      params.feature_id = f;
      //params = JSON.stringify

      const response = await requestApi(
        "/documents",
        "POST",
        JSON.stringify(params)
      );
      if (response.code == "success") {
        counter += 1;
      } else if (response.code == "authn_fail") {
        dispatch(signOut());
      } else {
        dispatch(onError(response.message));
      }
      if (counter == a.length) {
        const featureResponse = await requestApi(
          `/features?feature_id=${params.feature_id}`,
          "GET",
          {}
        );
        let modelId;
        try {
          modelId = featureResponse.data.features[0].model_id;
        } catch (e) {
          // Do nothing actually
        }

        dispatch({
          type: "POST_DATA_SUCCESS",
        });
        dispatch(fetchData(modelId));
      }
    });
  };

  // return {
  //   type: "POST_DATA_SUCCESS",
  // };
};

export const putData = (data, id) => {
  return async (dispatch) => {
    dispatch(putDataStart());

    let params = JSON.parse(data);
    params.id = id;

    //params = JSON.stringify

    const response = await requestApi(
      `/documents`,
      "POST",
      JSON.stringify(params)
    );
    if (response.code == "success") {
      const featureResponse = await requestApi(
        `/features?feature_id=${params.id}`,
        "GET",
        {}
      );
      let modelId;
      try {
        modelId = featureResponse.data.features[0].model_id;
      } catch (e) {
        modelId = null;
      }
      dispatch({
        type: "PUT_DATA_SUCCESS",
      });
      dispatch(fetchData(modelId));
    } else if (response.code == "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onError(response.message));
    }
  };
};

// return {
//   type: "POST_DATA_SUCCESS",
// };

export const closeModal = () => {
  return {
    type: "CLOSE_MODAL",
  };
};

export const getDocumentById = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "GET_DOCUMENT_BY_ID_START",
    });

    const response = await requestApi(`/documents/${id}`, "GET", {});
    if (response.code == "success") {
      const document = response.data.document.document_fields;

      let payload = {};
      payload.images =
        document.filter((m) => m.hasOwnProperty("icon_url")).length > 0
          ? [document.filter((m) => m.hasOwnProperty("icon_url"))[0].icon_url]
          : [];
      payload.display_name = document.filter((m) =>
        m.hasOwnProperty("title")
      )[0]
        ? document.filter((m) => m.hasOwnProperty("title"))[0].title
        : "";
      payload.short_text = document.filter((m) => m.hasOwnProperty("blurb"))[0]
        ? document.filter((m) => m.hasOwnProperty("blurb"))[0].blurb
        : "";
      payload.description_top = document.filter((m) =>
        m.hasOwnProperty("description_top")
      )[0]
        ? document.filter((m) => m.hasOwnProperty("description_top"))[0]
            .description_top
        : "";
      payload.description_middle = document.filter((m) =>
        m.hasOwnProperty("description_middle")
      )[0]
        ? document.filter((m) => m.hasOwnProperty("description_middle"))[0]
            .description_middle
        : "";
      payload.description_bottom = document.filter((m) =>
        m.hasOwnProperty("description_bottom")
      )[0]
        ? document.filter((m) => m.hasOwnProperty("description_bottom"))[0]
            .description_bottom
        : "";
      payload.videos =
        document.filter((m) => m.hasOwnProperty("video_url")).length > 0
          ? document
              .filter((m) => m.hasOwnProperty("video_url"))
              .map((m) => m.video_url)
          : [];
      payload.caution_text = document.filter((m) =>
        m.hasOwnProperty("caution")
      )[0]
        ? document.filter((m) => m.hasOwnProperty("caution"))[0].caution
        : "";
      payload.illustration_top =
        document.filter((m) => m.hasOwnProperty("image_top_url")).length > 0
          ? document
              .filter((m) => m.hasOwnProperty("image_top_url"))
              .map((m) => m.image_top_url)
          : [];
      payload.illustration_middle =
        document.filter((m) => m.hasOwnProperty("image_middle_url")).length > 0
          ? document
              .filter((m) => m.hasOwnProperty("image_middle_url"))
              .map((m) => m.image_middle_url)
          : [];
      payload.illustration_bottom =
        document.filter((m) => m.hasOwnProperty("image_bottom_url")).length > 0
          ? document
              .filter((m) => m.hasOwnProperty("image_bottom_url"))
              .map((m) => m.image_bottom_url)
          : [];
      payload.linked_to_pdf =
        document.filter((m) => m.hasOwnProperty("file_url")).length > 0
          ? document
              .filter((m) => m.hasOwnProperty("file_url"))
              .map((m) => m.file_url)
          : [""];
      payload.warning_text = document.filter((m) =>
        m.hasOwnProperty("warning")
      )[0]
        ? document.filter((m) => m.hasOwnProperty("warning"))[0].warning
        : "";
      payload.chapter = document.filter((m) => m.hasOwnProperty("chapter"))[0]
        ? document.filter((m) => m.hasOwnProperty("chapter"))[0].chapter
        : "";
      payload.related_features = document.filter((m) =>
        m.hasOwnProperty("related_features")
      )[0]
        ? document.filter((m) => m.hasOwnProperty("related_features"))[0]
            .related_features
        : [];
      dispatch({
        type: "GET_DOCUMENT_BY_ID_SUCCESS",
        payload: payload,
      });
    } else if (response.code == "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onError(response.message));
    }
  };
};

export const approve = (id, where, path) => {
  let models = store.getState().meta.models;
  let model_id =
    models && models.length > 0
      ? models.filter((m) => `/${m.label.toLowerCase()}` === path)[0]
        ? models.filter((m) => `/${m.label.toLowerCase()}` === path)[0].value
        : 1
      : 1;
  return async (dispatch) => {
    dispatch({
      type: "APPROVE_START",
    });

    const response = await requestApi(`/documents/${id}`, "PUT", {
      approved: 1,
      template_reference: "msr_v1",
    });
    if (response.code == "success") {
      dispatch({
        type: "APPROVE_SUCCESS",
      });
      dispatch(fetchData(model_id));
      if (where == "table") {
        dispatch(closeModal());
      }
    } else if (response.code == "authn_fail") {
      dispatch(signOut());
    } else {
      dispatch(onError(response.message));
    }
  };
};
